//
// Tools
// --------------------------------------------------
.tools {
    display: flex;
    justify-content: flex-start;

    .tool {
        display: flex;
        align-items: center;
        border: solid 1px $gray04;
        border-radius: 16px;
        padding: 2px;

        button {
            background: none;
            border: none;
        }
        .tool__button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            background: $gray05;
            border: solid 1px $gray05;
            cursor: pointer;
            border-radius: 16px;
            z-index: 10px;

            &.inactive {
                background: #fff;
                border: solid 1px $gray04;
                color: $gray03;
                cursor: none;
            }

            &:focus {
                outline: solid 1px $brand02;
                outline-offset: 2px;
                color: $brand02;
            }
        }

        .tool__info {
            display: flex;
            background: #fff;
            margin: 0 .5rem;
            padding: 0 .1rem;
            font-size: 14px;
            cursor: pointer;
            position: relative;
            z-index: 5;

            &.inactive {
                cursor: none;
            }

            &:focus {
                outline: none;
                border-bottom: solid 1px $brand02;
            }
        }
    }
}