//
// Events
// --------------------------------------------------
.events {
    .block__info {
        .block__short {
            max-width: 800px;
            margin: 1rem auto 2rem;
        }
    }
    .teaser {

        &.teaser--event {
            height: calc(100% - 2rem);
            margin-bottom: 2rem;
            cursor: default;

            .teaser__img {
                height: 260px;
            }
            .teaser__inner {
                padding: 2.5rem 2.5rem 2rem;
                text-align: center;
                position: relative;

                .teaser__tag {
                    background: $brand02;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: $font-size-small;
                    position: absolute;
                    padding: .5rem 1.5rem;
                    z-index: 10;
                    top: -1rem;
                    left: 0;
                }
                .teaser__info {
                    text-align: center;
                    font-size: 1rem;
                    display: block;
                }
                .teaser__title {
                    font-size: 1.4rem;
                    margin-top: 1rem;
                }
                .cta--event {
                    margin-top: 2rem;
                }
                .event__newsletter {
                    margin-top: 2rem;
                    
                    .vnr-newsletter-inline {
                        text-align: left;

                        .section__title {
                            display: none;
                        }
                        .vnr_newsletter-intro {
                            text-align: center;
                            margin-bottom: 1.5rem;
                        }
                        .vnr-newsletter-legal {
                            font-size: 14px;
                        }
                    }
                    .newsletter__content {
                        font-size: 1rem;
                        margin-bottom: 1rem;
                    }
                    .btn-primary {
                        width: 100%;
                        margin-top: .75rem;
                    }
                }
            }
        }
    }
    
    .teaser {
        .nss_error, .nss__success {
            padding: .5rem 1rem;
            margin-bottom: 1rem;
            border: solid 1px $brand02;
            font-size: 15px;
        }
        .nss__success {
            border: solid 1px $brand01;
        }

        &.teaser--event {
            .teaser__inner {

                .teaser__leagel {
                    font-size: 11px;
                    text-align: left;
                    line-height: normal;
                    margin-top: 1rem;
                }
            }
        }
    }
    .col-lg-4, .col-lg-6 {
        .teaser {

            &.teaser--event {
                .teaser__inner {
                    padding: 2.5rem 1rem 2rem;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .teaser {
        &.teaser--event {
            .teaser__inner {
                padding: 2.5rem 1rem 2rem;
            }
        }
    }
}
// Responsive
// --------------------------------------------------
