//
// Type
// --------------------------------------------------

h1 {
   @include font-title-h1; 
}
h2 {
   @include font-title-h2; 
}
h3 {
   @include font-title-h3; 
}
h4 {
   @include font-title-h4; 
}
.section__title {
    margin-bottom: 3rem;

    h2 {
      @include font-title-h1; 
    }
}
.font-small {
	font-size: $font-size-small;
}
.font-big {
	font-size: $font-size-big;
}
.brand {
   color: $brand01;
}
.brand2 {
   color: $brand02;
}
.has-text-align-right {
   text-align: right;
}
.has-text-align-left {
   text-align: left;
}
.has-text-align-center {
   text-align: center;
}
// Responsive
// --------------------------------------------------
@import "responsive/type-md";
@import "responsive/type-sm";