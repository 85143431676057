//
// Banner
// --------------------------------------------------
.banner-ext {
    max-width: 100%;
    display: block;
    margin-bottom: 2rem;
    text-align: center;

    img {
        max-width: 100%;
    }

    &.banner--mobile {
        display: none;
    }

    &:focus {
        outline: none;
    }
}
.banner {
    position: relative;
    overflow: hidden;

    .banner__inner {
        padding: 5rem 0;
    }
    .nss_error, .nss__success {
        padding: .5rem 1rem;
        margin-bottom: 1rem;
        border: solid 1px $brand02;
    }
    .nss__success {
        border: solid 1px $brand01;
    }
    .banner__title {
        @include font-title-h1;
        font-size: 1.8rem;
        color: $gray02;

        &.title--center {
            text-align: center;
        }
    }
    .banner__text {
        margin: 1rem 0 2rem;

        ul {
            font-size: 16px;
            text-align: left;
            margin-bottom: 2rem;
            list-style: none;

            li {
                padding-left: 26px;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    background: url(~img/gevestor/icon-check.svg) left center no-repeat;
                    background-size: 100%;
                    height: 20px;
                    width: 20px;
                }
            }
        }
        &.text-center {
            ul {
                text-align: center;
                padding-left: 0;
                li {
                    padding-left: 0;

                    &:before {
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
    .banner__content {
        margin: 1rem 0 3rem;
        font-size: 18px;
        ul {
            list-style: none;
            padding: 0;

            li {
                margin: .5rem 0;
                padding-left: 2.2rem;
                position: relative;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 22px;
                    width: 22px;
                    position: absolute;
                    top: 3px;
                    left: 0;
                    background: url(~img/gevestor/icon-check.svg) left center no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
    .banner__input {
        display: flex;
        width: 100%;
        align-items: center;
        .form-control {
            height: 50px;
        }
        .btn-primary {
            height: 50px;
        }
    }
    .banner__leagel {
        font-size: 12px;
        line-height: normal;
        margin-top: 1rem;

        a {
            color: $gray02;
        }
    }
    &.banner--dark {
        background: $gray02;
        color: #fff;

        a {
            color: #fff;
        }
        .banner__title {
            color: #fff;
        }

        .nss_error, .nss__success {
            background: rgba(255,255,255,.1);
        }

        .banner__leagel {
            a {
                color: #fff;
            }
        }
    }
    &.banner--back1 {
        background-color: #b8d297;
        background-image: url(~img/gevestor/back_banner_1.svg);
        background-repeat: no-repeat;
        background-position: right 150% top -2px;
        background-size: auto 100%;

        .btn-container {
            display: inline;
            position: absolute;
            right: 1.2rem;
            margin-top: -4rem;
        }
    }
    &.banner--full {
        position: relative;
        .banner__inner {
            padding-left: 6rem;
        }
        .banner__img {
            position: absolute;
            bottom: -20rem;
            width: 90%;
            transition: all 1.5s ease-in-out;
            height: 100%;

            &.visible {
                bottom: -7rem;
                transition: all 1.5s ease-in-out;
            }
        }
    }
    &.banner--article-cat {
        width: 100%;
        min-height: calc(100% - 1rem);
        padding: 2rem;

        .nss_error, .nss__success {
            margin-bottom: 2rem;
            font-size: 15px;
        }
        .banner__sub {
            text-transform: uppercase;
            font-size: $font-size-small;
        }
        .banner__title {
            font-size: 1.5rem;
        }
        .banner__text {
            margin: 1rem 0;

            ul {
                font-size: 16px;
                text-align: left;
                margin-bottom: 2rem;

                li {
                    padding-left: 26px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        background: url(~img/gevestor/icon-check.svg) left center no-repeat;
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

        input {
            width: 100%;
        }
        .form-display {
            display: block;
            position: relative;
            padding: 0;
            margin-bottom: 0;
            margin-top: 1.5rem;
            background: #fff;
            border-width: 1px;
            
            .control-label {
                font-size: 1rem;
                left: 1rem;
                top: .75rem;
            }
            input {
                padding: 0.75rem 1rem;
                font-size: 1rem;

                &:focus ~ label,
                &:not(:placeholder-shown) ~ label {
                    font-size: 13px;
                    top: -28px;
                    left: 0;
                    background: none;
                    color: #fff;
                    padding: 0;
                }
            }
            
            .btn-primary {
                width: 100%;
                left: 0;
                top: 10px !important;
                font-size: 15px;
                padding: .75rem 1rem;
                white-space: normal;
            }
        }
        .btn-primary {
            margin-top: 1rem;
            width: 100%;
            font-size: 15.5px;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        
        .banner__leagel {
            font-size: 11px;
            text-align: left;
            line-height: normal;
            margin-top: 1rem;
        }
        .vnr-newsletter-inline {
            padding: 0;

            .section__title {
                text-align: left;
                margin-bottom: 1rem;
            }
            .vnr_newsletter-intro {
                font-size: 16px; 
                line-height: 1.6;
                text-align: left;

                ul {
                    margin-bottom: 1rem;
                    margin-top: 0;
                    font-size: 16px;

                    li {
                        padding-left: 26px;
                        &:before {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
            .vnr-newsletter-textline {
                margin-right: 0;
            }
            #vnr-newsletter-form {
                .form-display {
                    display: block;
                    position: relative;
                    padding: 0;
                    
                    .control-label {
                        left: 1rem;
                        top: .75rem;
                    }
                    input {
                        padding: 0.75rem 1rem;

                        &:focus ~ label,
                        &:not(:placeholder-shown) ~ label {
                            font-size: 13px;
                            top: -20px;
                            left: 0;
                            background: none;
                            color: #fff;
                            padding: 0;
                        }
                    }
                    
                    .btn-primary {
                        width: 100%;
                        left: 0;
                        top: 10px !important;
                    }
                }

                input {
                    width: 100%;
                }
            }
            .vnr-newsletter-legal {
                font-size: 11px;
                line-height: .95;
                text-align: left;
                line-height: normal;
                margin-bottom: 0;
                padding-top: .5rem;
            }
        }   
    }
    .banner__search {
        display: flex;
        margin: 3rem 0;
        justify-content: center;
        align-items: center;

        form {
            margin: 0 0 0 2rem;

            .form-group {
                margin: unset;

                .category__search__form-control {
                    border: solid 2px #fff;
                    background-image: url(~img/gevestor/icon-search--white.svg);
                    background-repeat: no-repeat;
                    background-size: auto 66%;
                    background-position: left 1rem center;
                    border-radius: 2rem;
                    min-width: 360px;
                    height: 3.5rem;
                    padding-left: 4rem;
                    color: #fff;

                    &:focus {
                        background-image: url(~img/gevestor/icon-search.svg);
                        color: $gray02;
                    }
                }
            }
        }
    }

    &.banner--product {
        border-top: solid 1px $gray04;
        border-bottom: solid 1px $gray04;
        padding: 1.5rem 0;
        margin: 0 0 3rem;

        .product__image {
            max-height: 18rem;
        }
        .product__title {
            @include font-title-h3;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
        .product__price {
            display: block;
            flex-direction: column;

            .price__euro {
                @include font-title-h3;
            }
            .price__info {
                font-size: $font-size-xsmall;
            }
        }
        .btn {
            color: #fff;
            text-decoration: none;
        }
    }
}
.banner--product-sub {
    text-transform: uppercase;
    color: $gray02;
    margin: 2rem 0 .75rem;
}
.number__item {
    text-align: center;

    .number_icon {
        width: 110px;
        height: auto;
        margin-bottom: 0;
    }
    .counter {
        font-size: 50px;
        font-family: $font-family-display;
        color: $gray02;
    }
}

.banner__ext {
    text-align: center;
    margin-bottom: 3rem; 
}

.embed-responsive {
    margin-top: 3rem;
}
// Responsive
// --------------------------------------------------
@import "responsive/banner-md";
@import "responsive/banner-sm";