//
// Forms
// --------------------------------------------------
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}
.form-display.form-group {
	border: solid 2px $gray03;
	background: rgba($color: #fff, $alpha: .5);
	position: relative;
    padding: 1px 3px 6px;
    
    .control-label {
        position: absolute;
        top: 11px;
        left: 20px;
        pointer-events: none;
        padding: 0 7px;
        z-index: 1;
        color: $gray03;
        font-size: 20px;
        font-weight: normal;
        transition: $transition-base;
    }
    input {
        display: block;
        background: none;
        padding: 0 20px 0;
        font-size: 20px;
        border: solid 1px transparent;
        border-bottom: none;
        line-height: 53px;
        height: 53px;
        width: 100%;
        color: transparent;
        transition: $transition-base;
        box-shadow: none;
        color: $gray02;

        &::placeholder { 
            color: transparent;
        }

        &:focus {
            outline: none;
        }
    
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            font-size: 14px;
            color: $gray03;
            top: -30px;
            left: -5px;
            background: $gray06;
			padding: 1px 7px;
        }
    }

    .btn {
        position: absolute;
        right: 4px !important;
        top: 3px !important;
        white-space: nowrap;
		height: 52px;
		padding: 0 1.5rem;
    }
}


fieldset {
	margin: 0 0 30px;
	padding: 0;
	border: none;
}
.form-control {
  	outline: none;
  	-webkit-box-shadow: none !important;
 	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	border-color: $gray04;
	border-radius: 0;
	
	&:focus {
		border-color: $gray03;
	}
	&.control--small {
		font-size: $font-size-small;
		height: calc(1.2em + 1.2rem + 2px);
		padding: .2rem 1.5rem;
	}
}
.form-radio, .form-group {
	position: relative;
	margin-bottom: 30px;
}
.form-radio {
	margin-top: 30px;
}
.form-inline > .form-group, .form-inline > .btn {
	display: inline-block;
	margin-bottom: 0;
}
.form-group textarea {
	resize: none;
}

/* CONTACT FORM */
.form-group {
	position: relative;
	margin-bottom: 30px;
}
.label-before {
	font-size: 17px;
	margin-bottom: 12px;
}
.form-group .control-label {
	position: absolute;
	top: 12px;
	left: 0;
	pointer-events: none;
	padding: 0;
	z-index: 1;
	color: $gray03;
	font-size: 17px;
	font-weight: normal;
	-webkit-transition: all 0.28s ease;
	transition: all 0.28s ease;
}
.form-group.has-label-before .control-label {
	color: $gray03;
}
.form-group .bar {
	position: relative;
	border-bottom: 2px solid $gray04;
	display: block;
}
.form-group.error .bar {
	border-bottom: 2px solid $brand01;
}
.form-group .bar::before {
	content: '';
	height: 2px;
	width: 0;
	left: 50%;
	bottom: -2px;
	position: absolute;
	background: $brand01;
	-webkit-transition: left 0.28s ease, width 0.28s ease;
	transition: left 0.28s ease, width 0.28s ease;
	z-index: 2;
}
.form-group.error .bar::before {
	background: $brand01;
}
.form-group {
	.control-label {
        position: absolute;
        top: 13px;
        left: 0;
        pointer-events: none;
        padding: 0 7px;
        z-index: 1;
        font-size: 17px;
        font-weight: normal;
        transition: $transition-base;
		color: $gray02;
		display: flex;
		align-items: center;
    }
	input, textarea {
		display: block;
		background: none;
		padding: 12px 7px 10px;
		font-size: 17px;
		border: solid 1px transparent;
		border-bottom: none;
		margin-bottom: -2px;
		line-height: 24px;
		width: 100%;
		color: $gray01;
		transition: $transition-base;
		box-shadow: none;

		&::placeholder { 
			color: transparent;
		}

		&:focus {
			outline: none;
		}

		&:focus ~ label,
		&:not(:placeholder-shown) ~ label {
			font-size: 13px;
			top: -13px;
			left: 0;
			background: none;

			.icon {
				display: none;
			}
		}
	}
	textarea {
		height: 120px;
	}
}
.form-group {
	.error {
		.control-label {
			color: $brand01;
		}
	}
}
.form-group select:focus  ~.bar::before, .form-group input:focus ~ .bar::before, .form-group textarea:focus ~ .bar::before {
	width: 100%;
	left: 0;
}

.error-message {
	display: inline-block;
	margin-top: 5px;
	font-size: 15px;
	padding-left: 22px;
	color: $brand02;
	background-image: url(/app/themes/xlib/public/img/icon-error.png);
	background-position: left center;
	background-size: auto 13px;
	background-repeat: no-repeat;
	
	&.none {
		display: none;
	}
}
.honig {
	height: 0;
	width: 0;
	overflow: hidden;
}

/* SELECTBOX */
.form-group select {
	width: 100%;
	height: 45px;
	padding: 9px 15px;
	background: none;
	border: solid 1px #afbac3;
	border-bottom: none;
	border-radius: 0;
	line-height: 28px;
	box-shadow: none;
	-webkit-appearance: none; 
   	-moz-appearance: none;
   	appearance: none;
	background-size: 44px;
}
.form-group select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
.form-group option:not(:checked) {
  	color: black;
}

/* Custon Selectbox */
.custom-selectbox {
  	position: relative;
}
.custom-selectbox select {
  	display: none;
}
.select-selected {
	height: 45px;
	padding: 9px 15px;
	border: solid 1px #afbac3;
	border-bottom: solid 2px #afbac3;
	color: #212529;
	background: #fff;
	line-height: 28px;
}
.select-selected:after {
  	position: absolute;
  	content: "";
  	top: 5px;
  	right: 8px;
  	width: 35px;
  	height: 35px;
	background: #900;
	background-size: auto 16px;
	transform: rotate(90deg);
}
.select-selected.select-arrow-active:after {
  	transform: rotate(-90deg);
}
.select-items div {
  	border: solid 1px #eee;
	border-top: none;
	padding: 9px 15px;
  	cursor: pointer;
	color: #212529;
	background: #fff;
}
.select-items {
  	position: absolute;
  	top: 100%;
  	left: 0;
  	right: 0;
  	z-index: 300;
}
.select-hide {
  	display: none;
}
.select-items div:hover, .same-as-selected {
  	background-color: #f3f6f8;
}
.custom-selectbox +.bar {
	margin-top: -1px;
}

/* Radios & Checkboxes */
.checkbox label, .form-radio label {
	position: relative;
	cursor: pointer;
	padding-left: 30px;
	text-align: left;
	color: #212529;
	display: block;
}
.checkbox input, .form-radio input {
	width: auto;
	opacity: 0.00000001;
	position: absolute;
	left: 0;
}


/* RADIOS */
.radio {
	margin-bottom: 1rem;
}
.radio .helper {
	position: absolute;
	top: -1px;
	left: -1px;
	cursor: pointer;
	display: block;
	font-size: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #999;
}
.radio .helper::before, .radio .helper::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	margin: 2px;
	width: 19px;
	height: 19px;
	-webkit-transition: -webkit-transform 0.28s ease;
	transition: -webkit-transform 0.28s ease;
	transition: transform 0.28s ease;
	transition: transform 0.28s ease, -webkit-transform 0.28s ease;
	border-radius: 50%;
	border: solid 1px #afbac3;
}
.radio .helper::after {
	-webkit-transform: scale(0);
	transform: scale(0);
	background-color: $brand01;
	border-color: $brand01;
}
.radio label:hover .helper {
	color: $brand01;
}
.radio input:checked ~ .helper::after {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
}
.radio input:checked ~ .helper::before {
	color: #337ab7;
}


/* CHECKBOX */
.checkbox {
	margin-top: 30px;
	margin-bottom: 10px;
}
.checkbox .helper {
	color: #999;
	position: absolute;
	top: 1px;
	left: 2px;
	width: 18px;
	height: 18px;
	z-index: 0;
	border: 1px solid #afbac3;
	border-radius: 0;
	-webkit-transition: border-color 0.28s ease;
	transition: border-color 0.28s ease;
}
.checkbox .helper::before, .checkbox .helper::after {
	position: absolute;
	height: 0;
	width: 4px;
	background-color: $brand01;
	display: block;
	-webkit-transform-origin: left top;
	transform-origin: left top;
	border-radius: 0.25rem;
	content: '';
	-webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
	transition: opacity 0.28s ease, height 0s linear 0.28s;
	opacity: 0;
}
.checkbox .helper::before {
	top: 14px;
	left: 8px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.checkbox .helper::after {
	top: 10px;
	left: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.checkbox label:hover .helper {
	color: $brand01;
}
.checkbox input:checked ~ .helper {
	color: #afbac3;
}
.checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
	opacity: 1;
	-webkit-transition: height 0.28s ease;
	transition: height 0.28s ease;
}
.checkbox input:checked ~ .helper::after {
	height: 0.5rem;
}
.checkbox input:checked ~ .helper::before {
	height: 1.2rem;
	-webkit-transition-delay: 0.28s;
	transition-delay: 0.28s;
}
.radio + .radio, .checkbox + .checkbox {
	margin-top: 1rem;
}


.alert {
	padding: 1.5rem 1.25rem .5rem;
	border: solid 2px $brand01;
	background-color: $gray06;
	color: $gray01;
	margin-bottom: 3rem;
}

#ContactForm {
	.checkbox {
		.helper {
			top: 6px;
		}
	}
}

// Responsive
// --------------------------------------------------
@import "responsive/form-md";
@import "responsive/form-sm";