//
// Searchresults
// --------------------------------------------------
.searchresults {
    padding-bottom: 5rem;
    
    .block {
        padding: 4rem 0 1.5rem;

        .block__info {
            margin-bottom: 1.5rem;
        }
    }
    .form-display.form-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .results {
        padding-top: 4rem;

        .results__title {
            text-align: center;
            font-weight: $font-weight-bold;
            font-size: $font-size-h3;
            margin-bottom: 1.5rem;
        }

        .products {
            .teaser {
                padding-bottom: 6.5rem;

                .teaser__img {
                    max-height: 220px;

                    img {
                        max-height: 200px;
                    }
                }

                .teaser__inner {
                    padding: 0 1rem 0;

                    .teaser__title {
                        font-size: 1.1rem;
                    }
                }

                .teaser__footer {
                    position: absolute;
                    bottom: 0;
                    width: 100%;

                    .teaser__price {
                        &:before {
                            top: -.5rem;
                        }
                    }
                }
            }
        }

        .experts {
            .card {
                margin-bottom: 2rem;

                &.card--img {
                    height: calc(100% - 110px - 2rem);
                }
            } 
        }
        .articles {
            .article__item {
                border-bottom: solid 1px $gray04;
                padding: 1.5rem 0;

                &:first-child {
                    border-top: solid 1px $gray04;
                }
                .article__title {
                    @include font-title-h3;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .searchresults {
        .block {
            padding: 3rem 0 1.5rem;
        }
        .form-display.form-group {
            margin-bottom: 0;
            margin-top: 1.5rem;
        }
        .searchresults__header {
            padding-bottom: 4rem;

            .btn-container {
                position: absolute;
                right: auto;
                left: 0;
                top: auto;
                bottom: -75px;
                width: 100%;
                text-align: center;

                .btn {
                    position: relative;
                    bottom: 0;
                }
            }
        }

        .results {
            .button__container {
                margin-top: 2rem;
            }
            .products-wrapper .row {
                .col-lg-3 {
                    .product__item {
                        height: auto;
                        margin-bottom: 2rem;
                    }
                    &:last-child {
                        .product__item {
                            margin-bottom: 0;
                        }
                    }
                }
            }  
            .articles {
                .article__item {
                    .article__title {
                        font-size: 1.15rem;
                    }
                }
            }
            
        }
    }
}