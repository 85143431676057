/* Math JS Formula */
.article_formula {
    margin: 1rem 0 2rem 0;

    .result {
        padding: 0 0 0 12px;

        .label {
            display: inline-block;
            margin: 0 5px 0 0;
        }

        .number {
            display: inline-block;
            font-weight: bold;
            font-size: 20px;
        }
    }

    .formula_head {
        padding: .75rem 1rem;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .subhead {
            display: inline-block;
            margin-top: 0;
            font-size: 1.25rem;
        }

        .gv_label {
            display: inline-block;
            background: $gray02;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            position: absolute;
            padding: .25rem .75rem .15rem;
            z-index: 10;
            top: -.5rem;
            left: -.5rem;
        }
    }

    &.small {
        max-width: 600px;
        min-height: 280px;
        margin: 2.5rem auto 2rem;

        .formula_head .subhead {
            margin: 10px 0 10px 12px;
        }
        .form_row {
            clear: none;
        }

    }

    .hidden {
        display: none !important;
        visibility: hidden;
    }

    .btn_micro {
        position: relative;
        margin: 0 0 0 5px;
        padding: 5px 7px 0;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        border: solid 1px $gray02;
        color: $gray02;
        line-height: 13px;

        &:hover {
            .tooltip {
                display: block;
                opacity: 1;
            }
        }
    }

    .tooltip {
        background: $gray02;
        display: none;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.4;
        left: -12px;
        top: 32px;
        padding: 8px;
        position: absolute;
        width: 280px;
        z-index: 100;
        color: #fff;

        a {
            color: #fff;
        }

        &:after {
            content:"";
            border-color: transparent transparent $gray02 transparent;
            border-width: 8px;
            border-style: solid;
            position: absolute;
            left: 16px;
            top: -16px;
            z-index: 150;
        }

        &.right {
            left: auto;
            right: -12px;

            &:after {
                left: auto;
                right: 16px;
            }
        }
    }

    .is_calculate_formula_button {
        background: $brand01;
        color: #fff;
        text-decoration: none;
        border: none;
        position: relative;
        white-space: nowrap;

        &:hover {
            color: #FFF;
            background: darken($brand01, 10%);
        }
    }
}

.formula {
    background: $gray05;
    padding: 0 0 12px;
}
.ma-block {
    font-size: 1rem;
}
.formula_form {
    border: 1px solid $gray05;
    padding: 1rem;

    .form_row {
        clear: both;
        margin: 0 0 1rem;

        &.form_row_half {
            clear: none;
            float: left;
            width: 50%;
        }

        &>label {
            display: block;
            margin: 0 0 5px;
            cursor: pointer;
            font-size: 15px;
        }

        & input[type=text] {
            font-size: 15px;
            border: 1px solid #a6a6a6;
            outline: 0;
            padding: 5px 7px;
            width: 95%;
        }
    }
}
