//
// Stage MD
// --------------------------------------------------
@media (max-width: 1199px) {
    .stage {
        height: auto;

        .header-row {
            align-items: center;
        }

        .stage__inner {
            padding: 2rem 0;
        }

        .stage__title {
            font-size: 1.9rem;
        }

        .stage__bullets {
            font-size: 17px;
            margin: 1rem 0 2rem;

            li {
                padding-left: 1.8rem;
                &:before {
                    height: 19px;
                    width: 19px;   
                    top: 3px;
                    left: 0;
                }
            }
        }
        .stage__content {
            margin: 1rem 0 2rem;
            font-size: 17px;

            ul {
                font-size: 17px;
        
                li {
                    padding-left: 1.8rem;
        
                    &:before {
                        content: '';
                        height: 18px;
                        width: 18px;
                        top: 4px;
                    }
                }
            }
        }
        .stage__form {
            .form-group {
                .control-label {
                    position: absolute;
                    top: 15px;
                    font-size: 1rem;
                    left: 0.75rem;
                }
                input {
                    padding: 0 .75rem;
                    font-size: 1rem;
                    &:focus ~ label,
                    &:not(:placeholder-shown) ~ label {
                        font-size: 14px;
                        color: $gray03;
                        top: -30px;
                        left: -5px;
                        background: $gray06;
                        padding: 1px 7px;
                    }
                }
            }

            .btn--cta--wrapper {
                position: absolute;
                right: 5px;
                top: 5px;
            }
            .btn--cta {
                padding: 0.6rem 2rem;
                height: 51px;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 15px;
            }
        }
        
    }
}
@media (max-width: 991px) {
    .stage {
        .stage__form {
            display: block;

            .stage__input {
                margin-bottom: .5rem;
            }

            .btn--cta--wrapper {
                position: relative;
                left: 0;
            }
            .btn--cta {
                width: 100%;
            }
        }
        .stage__leagel {
            padding-top: 1rem;
        }
        .col-md-5 {
            img {
                height: auto !important;
            }
        }
    }
}
