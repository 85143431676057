//
// Teaser
// --------------------------------------------------
.teaser--right {
    .teaser__row {
        width: 175%;
        margin-left: -75%;
        z-index: 10;
        position: relative;
    }
}
.teaser--left {
    .teaser__row {
        width: 175%;
        margin-right: -75%;
        z-index: 10;
        position: relative;
    }
}
.teaser {
    background: #fff;
    height: 100%;
    position: relative;
    border-color: #eaeaea;
    box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.1);

    &.teaser--second {
      display: none;
    }
    &.teaser--half {
        height: calc(50% - 1rem);

        &:first-child {
            margin-bottom: 2rem;
        }
    }
    &.teaser--gray {
        background: $gray06;
        box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.2);  
    }
    
    .teaser__inner {
        padding: 1.5rem 1.5rem .75rem;
    }
    .teaser__img {
        display: block;
        height: 220px;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $transition-base;
        }
    }
    .teaser__time {
        font-size: $font-size-small;
        color: $gray03;
        padding-left: 1.4rem;
        background: url(~img/gevestor/icon-time.svg) left 3px no-repeat;
        background-size: auto 18px;
    }
    .teaser__info {
        font-size: $font-size-small;
        color: $gray03;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .teaser__cat {
            border: solid 1px $gray04;
            padding: .15rem 1rem;
            border-radius: 15px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            transition: $transition-base;
            margin-right: auto;
            
            .cat__text {
                position: relative;
                color: $gray03;
                z-index: 2;
                transition: $transition-base;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                border-radius: 15px;
                background-color: $gray03;
                transition: $transition-base;
                z-index: 1;
            }

            &:hover {
                text-decoration: none;
                &:before {
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: $transition-base;
                }

                .cat__text {
                    color: #fff;
                    transition: $transition-base;
                    text-decoration: none;
                }
            }
        }
        .teaser__author {
            margin-left: auto;
            display: inline-block;
            color: $gray02;
            position: relative;

            &:after {
                content: "";
                display: block;
                height: 1px;
                width: 0;
                background: $brand01;
                position: absolute;
                left: 0;
                bottom: 1px;
                transition: $transition-base;
            }

            &:hover, &:focus {
                color: $gray01;
                text-decoration: none;
                outline: none;
                
                &:after {
                    width: 100%;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.2);  

        .teaser__img {

            img {
                transform:scale(1.05);
                transition: $transition-base;
            }
        }
    }
    .teaser__content {
        display: block;
        margin-bottom: 1.5rem;

        .teaser__title {
            @include font-title-h3;
            margin: 1.5rem 0;
    
            a {
                color: $gray02;
    
                &:hover {
                    color: $gray01;
                    text-decoration: none;
                }
            }

            &:after {
                content: '';
                display: block;
                height: 2px;
                width: 0;
                background-color: $brand01;
                transition: $transition-base;
            }
        }

        .btn--teaser {
            transition: $transition-base;
        }
        &:hover, &:focus {
            text-decoration: none;
            outline: none;

            .btn--teaser {
                margin-right: -.5rem;
            }
        }

        &:focus {
            .teaser__title {
                color: $gray01;
    
                &:after {
                    width: 100%;
                }
            }
        }
    }
   
    .teaser__footer {
        display: flex;
        align-items: center;
    }
    .teaser__short {
        font-size: 16px;
    }

    &.teaser--large {
        background-size: cover;

        .teaser--large__inner {
            background: rgba(255,255,255,.95);
            position: absolute;
            left: 0;
            bottom: 0;
            width: 60%;
            padding: 1.5rem 1.5rem 4.5rem;
        }
    }

    &.menu__teaser {
        background: none;
        box-shadow: none;
        color: #fff;
        margin-bottom: 3rem;
        height: auto;

        .teaser__cat, .teaser__author {
            color: #fff;
        }
        .teaser__title {
            color: #fff;
            border-top: solid 1px $gray03;
            border-bottom: solid 1px $gray03;
            padding: .75rem 0;
            margin: 0 0 .75rem;
        }
    }

    .teaser__video {
        background: $brand01;
        color: #fff;
        text-transform: uppercase;
        font-size: $font-size-small;
        bottom: 0;
        right: 0;
        padding: .25rem 0 .65rem;
        position: absolute;
        z-index: 10;
        width: 96px;
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -20px;
            left: 0;
            border-width: 0 48px 20px;
            border-style: solid;
            border-color: transparent transparent $brand01;
        }
    }

    &.teaser--featured {
        display: block;
        text-decoration: none;

        .teaser__inner {
            .teaser__content {
                color: $gray01; 
                padding-bottom: 2rem;

                .teaser__title {
                    font-family: $font-family;
                    font-weight: $font-weight-bold;
                    margin: .25rem 0 1rem;
                }
            }
        }

        &:hover {
            text-decoration: none;
            box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.2) !important; 
        }
    }
    &.teaser--horizontal {
        display: block;
        height: auto;
        margin-bottom: 1.5rem;
        text-decoration: none;

        .teaser__img {
            height: 100%;
        }

        .teaser__inner {
            padding: 0 1rem 1rem;
            font-size: 14px;
            line-height: normal;
            color: $gray01;
            position: relative;

            .teaser__content {
                color: $gray01; 
                margin-bottom: 0;
            }
            .teaser__title {
                font-family: $font-family;
                font-weight: $font-weight-bold;
                font-size: $font-size-base;
                margin-bottom: .5rem;
                margin-top: 1rem;
            }
             
            .teaser__video {
                font-size: 12px;
                width: 76px;

                &::before {
                    top: -12px;
                    border-width: 0 38px 12px;
                }
            }
        }

        &.last {
            margin-bottom: 0;
        }

        &.horizontal--list {
            margin-bottom: 2rem;
            height: calc(100% - 2rem);
        }

        &:hover {
            text-decoration: none;
            box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.2) !important; 
        }

        &.teaser--100 {
            height: calc(100% - 1.5rem);
        }
    }
}
.teaser--img-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    cursor: pointer;
    color: $gray01;
    text-decoration: none;

    .teaser__img {
        height: 180px;
        width: 180px;
        border-radius: 50%;
        border: solid 1px $gray04;
        overflow: hidden;
        position: relative;
        margin-bottom: 1rem;

        img {
            min-height: 100%;
            transition: $transition-base;
        }
    }

    &:hover, &:focus {
        text-decoration: none;
        color: $brand01;

        .teaser__img {
            img {
                scale: 1.1;
            }
        }
    }
}
.col-md-4 {
    .teaser {
        margin-bottom: 0;
    }
}
.teaser--wall {
    .card-columns {
        grid-column-gap: 2rem;

        .card {
            border: none;
            background: none;
            margin-bottom: 2rem;

            .teaser {
                max-width: 100%;
                margin: 0;
                width: 100%;;
            }
        }
    }
    .teaser {
        height: auto;
    }
}
.related__cats {

    .cat__item {
        display: inline-block;
        position: relative;
        margin-right: .75rem;
        padding: .5rem 1rem;
        font-size: 15px;
        border: solid 1px $brand01;
        border-radius: 20px;
        margin-bottom: 1rem;
        cursor: pointer;
        transition: $transition-base;
        display: none;

        &.show {
            display: inline-block;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-radius: 20px;
            background-color: $brand01;
            transition: $transition-base;
            z-index: 1;
        }
        .item__text {
            position: relative;
            color: $brand01;
            z-index: 2;
            transition: $transition-base;
        }

        &:hover {
            text-decoration: none;
            &:before {
                height: 100%;
                left: 0;
                top: 0;
                transition: $transition-base;
            }

            .item__text {
                color: #fff;
                transition: $transition-base;
            }
        }
    }
}
.block--counter {
    h1.block__title {
        font-size: 2rem;
    }
    .teaser--counter {
        .teaser {
            .teaser__inner {
                padding: 1.5rem;
            }
        }
    }
}
@media (min-width: 576px) AND (max-width: 1199px) {
    .teaser--wall {
        .card-columns {
            column-count: 2;
        }
    }
}
// Responsive
// --------------------------------------------------
@import "responsive/teaser-md";
@import "responsive/teaser-sm";