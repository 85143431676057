.productAnchor {
  scroll-margin-top: 9.5rem;
}
.productAnchor.scrolled {
  scroll-margin-top: 5rem;
}

// Responsive
// --------------------------------------------------
@import "responsive/shop-md";
@import "responsive/shop-sm";