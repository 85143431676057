//
// Header MD
// --------------------------------------------------
@media (max-width: 991px) {
    .hide-md {
        display: none;
    }
    .sticky-header {
        border-bottom: none;
    }
    .header {
        border-bottom: solid 1px $gray04;
        background: #fff;
        margin-bottom: 1px;
        height: 84px;

        .header__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 84px;
            width: 100%;
            max-width: 100%;
            padding: 0;  
    
            img {
                height: 54px;
                width: auto;
            }
        }
        .header__links {
            justify-content: flex-end;
            width: 100%;
            padding: 1rem 0;

                .header__link {
                    .header__link__icon {
                        margin: 0 auto 0;
                    }
                }
          
        }

        .col-4.col-lg-8 {
            position: revert;
        }
    }
    body.search_open {
        overflow: hidden;
        position: fixed;
    }
    .header__search {
        position: fixed;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 50;
        
        .search__popular {
            margin-left: .1rem;
            margin-top: -1rem;
        }

        .search__icon {
            top: .35rem;
            left: .25rem;
            width: 34px;
            height: auto;
        }
        .close-icon {
            right: 1rem;
            top: 2.45rem;
        }
        .form-group {
            margin-top: 1.25rem;

            .control-label {
                left: 2.75rem !important;
            }
            .form-control {
                padding-left: 2.75rem;
            }
        }

        &.show {
            width: 100%;
            padding: 1rem;
            overflow: visible;
        }

        .search__suggest {
            max-height: calc(100% - 90px);
            overflow-y: scroll;
        }
        
    }
    .reader-btn {
        top: 80px !important;
    }
}
