//
// Base
// --------------------------------------------------
html {
  scroll-behavior: smooth;
}
body {
   @include font-base; 
}
.container {
    max-width: 1280px;
}
.article-container {
    max-width: 1000px;
}
img {
    max-width: 100%;
    height: auto;
}

/* SECTIONS */
.block {
    padding: 4rem 0 5rem;

    .block__links {
        background: $gray02;
        padding: 3rem 3rem 9rem 6rem;
        color: #fff;

        .link__item {
            font-family: $font-family-display ;
            font-size: 20px;
            padding: 1rem 0;
            color: #fff;
            border-top: solid 1px #fff; 

            &:first-child {
                border-top: none; 
                padding-top: 0;
            }
        }
    }

    &.teaser--right {
        .block__info {
            padding-left: 1.5rem;
        }
    }
    &.teaser--left {
        .block__info {
            padding-right: 3rem;
        }
    }

    .block__info {
        .block__title {

            .title__link {
                color: $gray02;
                transition: $transition-base;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;

                .link__arrow {
                    display: inline-block;
                    position: relative;
                    height: 20px;
                    width: 20px;
                    border-bottom: solid 4px $brand01;
                    border-right: solid 4px $brand01;
                    transform: rotate(-45deg);
                    margin-top: 0;
                    margin-left: 10px;
                    z-index: 10;
                    transition: $transition-base;
                }

                &:hover, &:focus {
                    text-decoration: none;
                    color: $gray03;
                    outline: none;

                    .link__arrow {
                        transition: $transition-base;
                        margin-right: -10px;
                    }
                }
            }
        }
        .block__short {
            margin: 1.5rem auto;
            max-width: 840px;
        }
        &.info--center {
            text-align: center;
        }
    }
    div.block__title {
        @include font-title-h1;
        text-align: center;
        margin-bottom: 3rem;
    }
    .articlecat__top {
        .article__title {
            @include font-title-h1;

            a {
                color: $gray01;
                text-decoration: none;
                transition: $transition-base;

                &:hover {
                    color: $brand01;
                }
            }
        }
    }
    .block__btn {
        margin-bottom: 4rem;
    }
    .block__img {
        display: flex;
        flex-wrap: wrap;
        
        .btn__more {
            margin-left: auto;
            margin-top: auto;
        }
    }

    &.block--part {
        padding-top: 2rem;
        padding-bottom: 5rem !important;
        
        .block__short {
            margin: 1.5rem 0 3rem;
            font-size: 20px;
        }
    }

    &.block--gray {
        background: $gray06;

        &.block--part {
            background-image: linear-gradient($gray06 50%, #ffffff 0%);
        }
    }
    &.block--gray1 {
        background: $gray05;
    }
    &.block--gray1 {
        background: $gray05;

        &.block--part {
            background-image: linear-gradient($gray05 50%, #ffffff 0%);
        }
    }
    &.block--dark {
        background-image: linear-gradient($gray02 50%, #ffffff 0%);
        padding-bottom: 2rem;
        margin-top: 5rem;

        .block__info {
            .block__title {
                margin-top: 0;
                color: #fff;
            }
            .block__short {
                margin: 1.5rem 0 3rem;
                color: #fff;
                font-size: 20px;
            }
        }
    }
    &.block--charts {
        padding: 0 0 3rem;
    }
    .block__filter {
        margin: 2rem 0 1rem;

        .filter__item {
            display: inline-block;
            position: relative;
            margin-right: .75rem;
            padding: .5rem 1rem;
            font-size: $font-size-small;
            border: solid 1px $brand01;
            border-radius: 20px;
            margin-bottom: 1rem;
            cursor: pointer;
            transition: $transition-base;
            
            input[type=checkbox] {
                display: none;
            }
            .item__text {
                position: relative;
                color: $brand01;
                z-index: 2;
                transition: $transition-base;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                border-radius: 20px;
                background-color: $brand01;
                transition: $transition-base;
                z-index: 1;
            }

            &:hover {
                &:before {
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: $transition-base;
                }

                .item__text {
                    color: #fff;
                    transition: $transition-base;
                }
            }
        }

        &.filter__selected {
            .filter__item {
                padding: .5rem 2.75rem .5rem 1rem;

                .close-icon {
                    transform: scale(.7);
                    right: .7rem;
                    top: .5rem;
                    z-index: 10;

                    span {
                        background: $gray02;
                    }
                }

                &:hover {
                    .close-icon {
                        span {
                            background: #fff;
                        }
                    }
                }
            }
        }

        .filter__link {
            display: block;
            position: relative;
            margin-top: 2rem;
            margin-bottom: 1rem;
            color: $gray02;
        }
    }
}


// Responsive
// --------------------------------------------------
@import "responsive/base-md";
@import "responsive/base-sm";