//
// Leadwall
// --------------------------------------------------
.lw__wrapper {
    margin-top: -120px;
    position: relative;

    &:before {
        content: '';
        display: block;
        height: 120px;
        background: #FFFFFF;
        background: -webkit-linear-gradient(bottom, #FFFFFF, #FFFFFF00);
        background: -moz-linear-gradient(bottom, #FFFFFF, #FFFFFF00);
        background: linear-gradient(to top, #FFFFFF, #FFFFFF00);
    }
    .lw__inner {
        position: relative;
        border: solid 1px $gray04;
        background: #fff;
        max-width: 620px;
        padding: 1.5rem 2.5rem;
        margin: 0 auto;
    }
    .lw__badge {
        position: absolute;
        background: $gray02;
        color: #fff;
        font-size: $font-size-small;
        text-transform: uppercase;
        padding: .5rem 1rem;
        left: -10px;
        top: -10px;
    }
    .lw__image-container {
        text-align: center;
        padding-bottom: 1.5rem;

        .lw__image {
            max-width: 160px;
            height: auto;
        }
    }
    .lw__title {
        text-align: center;
        font-size: $font-size-big;
        font-family: $font-family-display;
        padding: 0 2.5rem;
    }
    .lw__form {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .lw-form__submit {
            width: 100%;
            margin-top: .75rem;
        }
    }
    .lw__leagel {
        font-size: 13px;
        line-height: 1.5;
        color: $gray03;
    }
}