//
// Footer MD
// --------------------------------------------------
@media (max-width: 767px) {
    .footer__nav {    
        &.nav--horizontal {
            display: block;
        }
    }
}

// Responsive
// --------------------------------------------------
