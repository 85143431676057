//
// Corporate Template Breadcrumb
// --------------------------------------------------
.breadcrumbs {
    border-radius: 0;
    background: none;
    padding: .75rem 0;
	  font-size: $font-size-small;
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    list-style: none;
    overflow-x: auto;
    scrollbar-width: none;
    margin-bottom: 2rem !important;

    .breadcrumb-item {
        display: inline-block;
        padding-left: 0.5rem;
        a {
            color: $gray01;

            &:hover {
                color: $gray01;
                text-decoration: none;
            }
        }
    }
}
.breadcrumb-item + .breadcrumb-item::before, .breadcrumb-item::before, .breadcrumbs__toggle::before {
    content: "";
    background: url("~img/gevestor/arrow-right-gray.svg") center center no-repeat;
    background-size: auto 100%;
    height: 11px;
    float: left;
    width: 8px;
    margin-right: 8px;
    margin-top: 8px;
}
.breadcrumb-item.first {
  padding-left: 0;
}
.breadcrumb-item.first::before {
  content: none;
}

.breadcrumbs.breadcrumbs_overflow.breadcrumbs_expanded li {
    display: inline-block;
}
  .breadcrumbs.breadcrumbs_overflow.breadcrumbs_expanded .breadcrumbs__toggle a:before {
    display: inline-block;
    content: "--";
  }
  .breadcrumbs.breadcrumbs_overflow li {
    display: none;
  }
  .breadcrumbs.breadcrumbs_overflow li:last-child, .breadcrumbs.breadcrumbs_overflow li:first-child, .breadcrumbs.breadcrumbs_overflow li.breadcrumbs__toggle, .breadcrumbs.breadcrumbs_overflow li.last {
    display: inline-block;
  }
  .breadcrumbs.breadcrumbs_overflow .breadcrumbs__toggle {
    display: inline-block;
    padding-left: 0.5rem;
  }
  .breadcrumbs.breadcrumbs_overflow .breadcrumbs__toggle a:before {
    content: "...";
  }
  .breadcrumbs .breadcrumbs__toggle {
    display: none;
  }
  .breadcrumbs .breadcrumbs__toggle a {
    color: $brand01;
  }
  .breadcrumbs .breadcrumbs__toggle a:hover {
    text-decoration: none;
  }


// Responsive
// --------------------------------------------------

