form .frc-captcha {
  display: none;
}

.va-captcha-progress-wrapper {
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.va-captcha-progress-wrapper .captcha-progress {
  display: block;
  height: 100%;
  position: absolute;
  width: 0%;
  text-align: right;
  background-color: black;
  background: rgba(0, 0, 0, 0.6);
  transition: 500ms ease width;
}
