//
// Banner
// --------------------------------------------------
@media (max-width: 991px) {
    .banner {
        .banner__inner {
            padding: 3.5rem 0;
        }
        .banner__img {
            position: absolute;
            bottom: -11rem;
            width: 90%; 
        }
        .banner__title {
            font-size: 1.6rem;
        }
        .banner__bullets {
            font-size: 18px;

            li {
                margin: .5rem 0;
                padding-left: 2.2rem;

                &:before {
                    height: 22px;
                    width: 22px;
                    top: 3px;
                }
            }
        }

        .banner__input {
            padding-right: 0;
        }
    }
    .number__item {
        text-align: center;

        .number_icon {
            width: 90px;
            margin-bottom: .75rem;
        }
        .counter {
            font-size: 38px;
        }
    }   
}