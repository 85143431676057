//
// Stage
// --------------------------------------------------
.stage {
    height: 560px;
    background-image: linear-gradient(to left, $gray05 40%, transparent 60%);
    overflow: hidden;
    margin-top: -1px;
    

    .container, .row {
        height: 100%;
    }

    .col-md-5 {
        display: flex;

        img {
            margin-top: auto;
            margin-left: auto;
            width: 95%;    
        }
    }

    .stage__inner {
        padding: 3.5rem 0 0;
    }

    .stage__title {
        @include font-title-h1;
        font-size: 3rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }
    .stage__content {
        margin: 1rem 0 3rem;
        ul {
            list-style: none;
            font-size: 21px;
            padding: 0;
    
            li {
                margin: .5rem 0;
                padding-left: 2.2rem;
                position: relative;
    
                &:before {
                    content: '';
                    display: inline-block;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    background: url(~img/gevestor/icon-check.svg) left center no-repeat;
                    background-size: 100%;
                }
            }
        }
    }

    .stage__leagel {
        font-size: 12px;
        line-height: normal;
        a {
            color: $gray02;
        }
    }
    .nss_error_wrapper {
        position: absolute;
        left: 15px;
        top: 240px;
        width: calc(100% - 30px);
        max-width: 100%;
        z-index: 10;
        text-align: center;

        .nss_error, .nss__success {
            padding: .75rem 1rem;
            margin-bottom: 1rem;
            border: solid 2px $brand02;
            width: 100%;
            background: #fff;
        }
        .nss__success {
            border: solid 2px $brand01;
        }
    }
    .stage__form {
        display: flex;
        width: 100%;
        align-items: center;
        margin-right: 2rem;
        position: relative;

        .form-display {
            width: 100%;
        }
        .form-group {
            margin-bottom: 1rem;
        }

        .btn--cta--wrapper {
            position: absolute;
            right: 6px;
            top: 6px;
        }
        .btn--cta {
            padding: 0.7rem 2rem;
        }
    }
}
.obituary {
    .obituary__inner {
        border: solid 5px #000;
        padding: 2rem;
        text-align: center;
        color: #000;
        font-family: $font-family-display;

        p, h2 {
            color: #000;
        }
        p {
            font-size: 16px;
        }
        h2 {
            font-weight: bold;
        }
    }
}
// Responsive
// --------------------------------------------------
@import "responsive/stage-md";
@import "responsive/stage-sm";
