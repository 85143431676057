//
// Simplify Template Main Navigation
// --------------------------------------------------
@media (min-width: 992px) {
    .mainmenu {
        position: relative;
        padding: 0;
        background-color: #fff;
        border-top: solid 1px $gray04;
        z-index: 60;

        .container {
            display: flex;
            position: relative;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        .buttons--mobile {
            display: none;
        }
        .nav-btn {
            flex: 3;
            display: flex;
            position: relative;
            align-items: center;

            .nav-links {
                flex: 2;  
                @include font-nav;

                > ul {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }

                .nav-link {
                    padding: 0;

                    > a {
                        min-height: 76px;
                        padding: 2px 1.25rem 0;
                        color: $gray01;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        transition: $transition-base;
                        position: relative;
                        overflow: hidden;

                        &:before, &:after {
                            display:block;
                            position: absolute;
                            top: 1.6rem;
                            left: .5rem;
                            content: '';
                            height: 28px;
                            width: 3px;
                            background: $gray04; 
                            transition: $transition-base;
                        }
                        &:after {
                            height: 0;
                            background: $brand01; 
                        }

                        &.active, &:hover, &:focus {
                            &:after { 
                                animation: linedown .5s forwards;   
                            }
                        }

                        &:hover {
                            color: $gray02;
                        }
                        
                    }

                    &:hover {
                        > a {
                            text-decoration: none;
                            color: $gray02;
                        }
                        > .dropdown {
                            display: block;
                        }
                    }

                    > a {
                        &:focus {
                            outline:0;
                            color: $gray02;
                        }
                    }
                    
                    &:first-child {
                        > a {
                            padding-left: .85rem;

                            &:before, &:after {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .dropdown {
            ul {
                .dropdown-link {
                    display: block;
                    position: relative;
        
                    a {
                        display: flex;
                        align-items: start;
                        color: $gray02;
                        padding: .35rem 0;
                        transition: $transition-base;

                        &:before {
                            content: '';
                            display: block;
                            height: 0;
                            width: 0;
                            border-top: solid 2px $brand01;
                            border-right: solid 2px $brand01;
                            opacity: 0;
                            transform: rotate(45deg);
                            margin-right: 0;
                            margin-top: .5rem;
                            transition: $transition-base;
                        }
                        &:hover {
                            &:before {
                                width: 8px;
                                height: 8px;
                                opacity: 1;
                                margin-right: .5rem;
                            }
                        }
                    }

                    &:hover {
                        > a {
                            text-decoration: none;
                            color: $gray03;
                        }
                    }
                }
            }

            &.first {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-color: #fff;
                padding: 1rem;
                opacity: 0;
                pointer-events: none;
                transition: $transition-base;
                box-shadow: -1px 12px 24px 0px rgba(75, 81, 91, 0.25);
                border-top: solid 1px $gray04;

                > ul {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;

                    > .dropdown-link  {
                        flex: 0 0 25%;
                        max-width: 25%;
                        padding: 1rem;

                        a {
                            font-weight: $font-weight-bold;
                            border-bottom: solid 1px $gray04;
                            margin-bottom: .5rem;
                        }
                    }
                }
            }

            &.second {
                ul {
                    position: relative;
                    display: block;

                    > .dropdown-link {
                        flex: 0 0 100%;
                        max-width: 100%;
                        font-weight: $font-weight-normal;

                        > a {
                            font-weight: $font-weight-normal;
                            text-transform: none;
                            font-size: .9rem;
                            border-bottom: none;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .menu__buttons.buttons--desk {
            .icon-links {
                display: flex;
                justify-content: flex-end;

                .icon__button {
                    margin: 0 .5rem;
                    max-width: 90px;
                }
            }
        }
    }

    .sticked {
        .mainmenu {
            .nav-btn {
                .nav-links {
                    .nav-link {
    
                        > a {
                            min-height: 45px;
    
                            &:before, &:after {
                                top: .85rem;
                                height: 20px;
                            }
                            &:after {
                                height: 0;
                            }
    
                            &.active, &:hover, &:focus {
                                &:after { 
                                    animation: linedownstick .5s forwards;   
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .nav-link:hover > .dropdown {
        transform: translate(0, 0);
        opacity: 1;
        pointer-events: auto;
    }

    .nav-toggle {
        display: none;
    }   
}
@media (min-width: 992px) and (max-width: 1239px) {
    .mainmenu {
        .nav-btn {

            .nav-links {
                .nav-link {
                    font-size: .95rem;
                    > a {
                        padding: 2px .75rem 0;
                    }
                }
            }
        }
        .dropdown {
            &.first {

                > ul {

                    > .dropdown-link  {
                        flex: 0 0 33.333%;
                        max-width: 33.333%;
                    }
                }
            }
        }
    }
}
.hamburger-menu-container{
    flex: 1;
    display: none;
    align-items: center;
    justify-content: center;


    .hamburger-menu {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
            width: 1.5rem;
            height: 2px;
            border-radius: 3px;
            background-color: $gray02;
            position: relative;
            z-index: 1001;
            transition: .5s;
        }
    }


    .hamburger-menu div:before,
    .hamburger-menu div:after{
        content: '';
        position: absolute;
        width: inherit;
        height: inherit;
        background-color: $gray02;
        border-radius: 3px;
        transition: .5s;
    }

    .hamburger-menu div:before{
        transform: translateY(-8px);
    }

    .hamburger-menu div:after{
        transform: translateY(8px);
    }
}
#check {
    position: absolute;
    top: 2.25rem;
    left: 1rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90;
    cursor: pointer;
    opacity: 0;
    display: none;
}
#check:checked ~ .hamburger-menu-container .hamburger-menu div {
    background-color: transparent;
}
#check:checked ~ .hamburger-menu-container .hamburger-menu div:before{
    transform: translateY(0) rotate(-45deg);
}
#check:checked ~ .hamburger-menu-container .hamburger-menu div:after{
    transform: translateY(0) rotate(45deg);
}

@keyframes linedown {100% {height: 28px}}
@keyframes linedownstick {100% {height: 20px}}

// Responsive
// --------------------------------------------------
@import "responsive/mainmenu-md";