//
// Conversion Banner SM
// --------------------------------------------------
@media (max-width: 519px) {
    .category_conversion-banner {
        margin-top: 3rem;
        
        iframe {
            height: 700px;
        }
    }
    .conversion-banner {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;

        .banner__content--right {
            padding: 4rem 1.5rem 1rem;
        }
        .banner__content--left {
            padding: 2rem 1.5rem 1.5rem;
        }
        .banner__title {
            font-size: 1.5rem;
            margin-bottom: .25rem;
        }
        .banner__form-group {
            margin-top: 1rem;
            flex-direction: column;
            background: none;
            padding: 0;
    
            .banner__input {
                margin-bottom: .5rem;
                padding-top: .4rem;
                padding-bottom: .4rem;
            }
            .btn-primary {
                font-size: 14px; 
            }
        }
        &.cb--01 {
            background: linear-gradient(177deg, rgba(111,179,22,1) 6%, rgba(223,239,203,1) 100%);

            .banner__mask {
                width: 100%;
                height: 44%;
                background-image: url(~img/gevestor/cb-back-01--sm.svg);
                background-position: right bottom;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }

            .banner__icon {
                width: 7rem;
                height: 7rem;
                margin: 1.5rem 0 5.5rem;
            }
        }
        &.cb--02, &.cb--03 {
            .banner__image {
                height: 240;
                
                .image__mask {
                    width: 85%;
                    height: 100%;
                    right: -6rem;
                }
            }
            .banner__content--right {
                padding: 2rem 1.5rem 1rem;
            }
        }

        &.cb--05, &.cb--06 {
            .banner__image {
                height: 300px;
    
                .image__mask {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-image: url(~img/gevestor/cb-back-05--sm.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                }
            }
        }
        &.cb--06 {
            .banner__image {
                .image__mask {
                    background-image: url(~img/gevestor/cb-back-06--sm.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                }
            }
        }
    }
}
