//
// Conversion Banner MD
// --------------------------------------------------
@media (max-width: 991px) {
    .conversion-banner {
        .banner__content--right {
            padding: 1rem 2rem 1rem 1rem;
        }
        .banner__title {
            font-size: 1.8rem;
        }
        .banner__text {
            font-size: 1rem;
        }
        .banner__smalltext {
            font-size: 11px;
        }
        &.cb--01 {
            .banner__mask {
                width: 41%;
            }

            .banner__icon {
                width: 8rem;
                height: 8rem;
            }
        }
    }
}
