//
// Article
// --------------------------------------------------
@media (max-width: 1199px) {
    .article__start {
        .articlecat__top {
            padding-right: 0;
        }
        .article__info {
            margin-top: 1rem;
        }
        .article__more {
            display: block;
    
            .more__buttons {
                margin-left: 0;

                .btn {
                    width: 100%;
                    margin-top: 1rem;
                }
            }
        }
    }
    .article__full {
        padding-bottom: 4rem;
        h1 {
            margin-top: .6rem;
            margin-bottom: 2rem;
        }
        h2 {
            margin-bottom: 1rem;
            margin-top: 2rem;
        }
        .article__header-image {
            position: relative;
            width: 100%;
            overflow: hidden;
            padding-bottom: 52.5%;

            img {
                position: absolute;
                width: 100%;
                height: auto;
            }

            .header-image__copy {
                position: absolute;
                bottom: 0;
                width: 100%;
                background-color: rgba(255,255,255,0.7);
                font-size: 10px;
                line-height: normal;
                padding: .5rem 1rem;
            }
        }
    }
    .article__author {
        padding: 2rem 1rem;
        text-align: center;

        .author__img {
            width: 75%;
            max-width: 200px;
            margin: 1.25rem auto;
        }

        .author__form {
            display: block;

            .control-label {
                font-size: 17px;
                left: 1rem;
                top: 7px;

                &:focus ~ label,
                &:not(:placeholder-shown) ~ label {
                    top: -17px;
                    left: 20px;
                    padding: 2px 7px;
                    font-size: 13px;
                }
            }

            .form-display {
                margin-bottom: 1rem;

                input {
                    padding: 0 1rem 0;
                    font-size: 17px;
                    line-height: 42px;
                    height: 42px;
                }
            }
            .btn--cta {
                margin: 0;
                position: relative;
                width: 100%;
                right: 0;
                top: 0;
            }
        }
    }

    .article__state {
        padding-top: 0;
        top: 60px;

        .article__nav {
            display: block;
        }
    }
    .article__nav {
        padding-right: 0;
        padding-top: 0;
        margin-bottom: 1rem;
    }
    .anker__title, .anker__toggle {
        font-size: 16px;
        padding: 1rem 0 .75rem;
        background: none;
        border-top: solid 1px $gray04;
        cursor: pointer;
        border-bottom: solid 1px $gray04;

        .circle-plus {
            display: block;
            right: 0;
        }
    }
    .anker__nav {
        transition: max-height 0.25s ease-in;
        max-height: 0;

        .anker__inner {
            padding: 0 0 1.5rem;

            .anker__item {
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }
        }
        
        &.open {
            height: auto;
            max-height: 500px;
        }
    }
    .sidebar {
        &.sticky-top {
            position: relative;
            z-index: 40;
        }
        .article__nav {
            display: none;
        }
    }

    .article__editor {
        .wp-block-table {
            display: block;
            width: 100%;
            overflow-x: auto;

            table {
                width: auto;
            }
        }

        .teaser-box {
            .wp-block-columns {
                .wp-block-column {
                    max-width: 100%;
                    margin: 0 0 2rem;
                }
            }
        }
        .wp-block-media-text {
            .wp-block-media-text__content {
                padding: 2rem 0 1rem;
            }
    
            &.has-media-on-the-right {
                .wp-block-media-text__content {
                    padding: 2rem 0 1rem;
                }
            }
            &.is-style-gray {
                .wp-block-media-text__content {
                    padding: 2rem 1rem 1.5rem;
                }
            }
        }
    }

    .sidebar-sticked .anker__toggle {
        margin-bottom: 0;
    }
}
// Responsive
// --------------------------------------------------