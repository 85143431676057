//
// Conversion Banner
// --------------------------------------------------
.category_conversion-banner {
    margin-top: 3rem;
    
    iframe {
        height: 400px;
        width: 100%;
        border: none;
        padding: 0;
        overflow: hidden;
    }
}
.conversion-banner {
    margin: 3rem 0;
    background: $brand01;

    .banner__content--right {
        padding: 1.5rem 3rem 1.5rem 0;
    }
    .banner__content--left {
        padding: 1.5rem 0 1.5rem 3rem;
    }
    .banner__title {
        font-family: $font-family;
        font-weight: $font-weight-bold;
        font-size: 2rem;
        color: $gray01;
        margin-bottom: .5rem;
    }
    .banner__text {
        font-weight: $font-weight-bold;
        font-size: 1.15rem;
        color: $gray01;
    }
    .banner__form-group {
        margin-top: 1.25rem;
        display: flex;
        background: #fff;
        padding: 2px;

        .banner__input {
            border: solid 1px transparent;
            flex: 1;
            font-size: $font-size-small;
            color: #000;
            padding-right: 1rem;
            padding-left: 1rem;
        }
        .btn-primary {
            font-size: 14px; 
        }
    }
    .banner__smalltext {
        margin-top: 1rem;
        font-size: 12px;
        line-height: normal;
    }
    &.cb--01 {
        position: relative;
        background: linear-gradient(90deg, rgba(111,179,22,1) 6%, rgba(223,239,203,1) 100%);

        .banner__mask {
            width: 46%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(~img/gevestor/cb-back-01.svg);
            background-position: right top;
            background-repeat: no-repeat;
            background-size: auto 100%;
        }

        .banner__icon {
            width: 11rem;
            height: 11rem;
            display: flex;
            justify-content: center;
            align-items: center; 

            img {
                max-height: 100%;
            }
        }
    }
    &.cb--02, &.cb--03 {
        position: relative;
        background: $gray02;
        color: #fff;

        .banner__title, .banner__text {
            color: #fff;
        }
        .banner__image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            .image__mask {
                width: 75%;
                height: 100%;
                position: absolute;
                right: -4.25rem;
                top: 0;
                background-image: url(~img/gevestor/cb-back-02.svg);
                background-position: right top;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }

            img {
                object-fit: cover;
                height: 100%
            }
        }
    }
    &.cb--03 {
        background: #E0EFCB;
        color: #000;

        .banner__title, .banner__text {
            color: #000;
        }
        .banner__image {
            .image__mask {
                background-image: url(~img/gevestor/cb-back-03.svg);
                background-position: right top;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
        }
    }

    &.cb--05, &.cb--06 {
        position: relative;
        background: $gray02;
        color: #fff;

        .banner__title, .banner__text {
            color: #fff;
        }
        .banner__image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;

            .image__mask {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url(~img/gevestor/cb-back-05.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }

            img {
                object-fit: cover;
                height: 100%
            }
        }
    }
    &.cb--06 {
        background: $gray05;
        color: #000;

        .banner__title, .banner__text {
            color: #000;
        }
        .banner__image {
            .image__mask {
                background-image: url(~img/gevestor/cb-back-06.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: auto 100%;
            }
        }
    }
}

// Responsive
// --------------------------------------------------
@import "responsive/conversion-md";
@import "responsive/conversion-sm";