//
// Display SM
// --------------------------------------------------
@media (max-width: 767px) {
    .display {
        .block__info {
            .block__short {
                margin: 0 auto 2rem;
            }
        }
        .display__header-image {
            max-width: 100%;
            overflow: hidden;
            margin-bottom: 2rem;

            img {
                width: 200%;
                margin-left: -35%;
                max-width: 160%;
            }
        }
        .display__box {
            height: auto !important;
            max-width: 360px;
            margin: 0 auto 2rem;
        }
        
    }
}