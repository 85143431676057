//
// Teaser MD
// --------------------------------------------------
@media (max-width: 991px) {
    .teaser--right {
        .teaser__row {
            width: 175%;
            margin-left: -75%;
            z-index: 10;
            position: relative;
        }
    }
    .teaser--left {
        .teaser__row {
            width: 175%;
            margin-right: -75%;
            z-index: 10;
            position: relative;
        }
    }
    .teaser {
        .teaser__inner {
            padding: 1.5rem 1.25rem .75rem;
        }
        .teaser__img {
            height: 220px;
            overflow: hidden;
        }
        .teaser__info {
            font-size: 14px;

            .teaser__cat {
                padding: .1rem .5rem;
            }
        }

        .teaser__title {
            font-size: $font-size-h3-md;
        }
    }
    .teaser--wall {
        padding-top: 3rem
    }
}