//
// Header MD
// --------------------------------------------------
@media (max-width: 767px) {
    .header {
        height: 74px;
        
        .col-lg-4 {
            padding-left: 5px;
            padding-right: 5px;
        }
        .header__logo {
            height: 74px;
    
            img {
                height: 44px;
                width: auto;
            }
        }

        .col-lg-8 {
            justify-content: space-between;
        }
        .header__links {
            width: 100%;
            
            .header__link {
                margin-left: .75rem;

                .header__link__icon {
                    margin: 0 auto 0;
                }
            }
        }
    }
    .sticked {
        .header {
            height: 64px;
            .header__logo {
                padding: .7rem 0;
                max-width: 90%;      
            }
        }
    }
    .header__search {
        .search__suggest {
            .suggestions-wrapper {
                max-height: calc(100vh - 220px);
                overflow-y: scroll;
            }
        }
    }
}
