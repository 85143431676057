//
// Button
// --------------------------------------------------
a:hover, a:focus, a:active,
.btn-primary:hover,.btn-primary:focus,.btn-primary:active, 
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus, .btn:active, .btn:hover,button.focus, button:focus {
    box-shadow: none !important;
    outline: 0;
}
a {
    text-decoration: none;

    &:focus {
        outline: solid 1px $brand01;
        outline-offset: 3px;
        text-decoration: none !important;
    }
}
.button__container {
    margin-top: 2rem;
    
    &.btn-center {
        text-align: center;
    }
} 
.btn {
    @include font-btn;
    border-radius: 0;
    
    &.btn-primary {
        background: $brand01;
        border: solid 3px $brand01;
        padding: 0.5rem 1.5rem;
        position: relative;
        white-space: nowrap;
        z-index: 3;

        &:hover, &:focus, &:active {
			background: $brand01;
        }
        
        &:before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: -3px;
            width: calc(100% + 6px);
            height: 0;
            background-color: $gray02;
            transition: $transition-base;
            z-index: 1;
        }

        .btn__text {
            position: relative;
            z-index: 3;
        }

        &:hover, &:focus {
            &:before {
                height: calc(100% + 6px);
                left: -3px;
                top: -3px;
                transition: $transition-base;
            }
            .btn__text {
                color: #fff
            }
        }
        &:focus {
            outline: solid 2px $brand02;
            outline-offset: 3px;
            text-decoration: none !important;
        }
        &.btn--cta {
            background: $brand02;
            border-color: $brand02;

            &:hover {
                &:before {
                    background: #f96a3c;
                }
            }
        }

        &.btn--outline {
            color: $brand01;
            background: transparent;

            &:before {
                background-color: $brand01;
            }

            &:hover {
                .btn__text {
                    color: #fff
                }  
            }
        }
        .load-spin {
            display: block;
            height: 20px;
            width: 20px;
            border: solid 2px transparent;
            border-radius: 50%;
            transition: all 150ms ease-in-out;
            position: absolute;
            right: 1.25rem;
            top: 10px;
            z-index: 20;
            transform: scale(0, 0);

            &:after, &:before {
                content: "";
                display: block;
                background-color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
            }
            
            &:before {
                height: 10px;
                width: 2px;
                margin-left: -1px;
                margin-top: -5px;
            }

            &:after {
                height: 2px;
                width: 10px;
                margin-top: -1px;
                margin-left: -5px;
            }
        }
        &.btn-spinn {
            color: #fff;
            cursor: pointer;
            position: relative;
            transition: $transition-base;
            z-index: 10;

            &:hover {
                padding-right: 3.5rem;

                .load-spin {
                    border: solid 2px #fff;
                    margin-left: 7px;
                    transform: scale(1, 1);
                }
            }
            &.loading {
                padding-right: 3.5rem;

                .load-spin {
                    border: solid 2px #fff;
                    margin-left: 7px;
                    transform: scale(1, 1);
                    animation-name: spin;
                    animation-duration: 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }
            }
        }
    }

    &.btn--teaser {
        padding: 0 !important;
        height: 46px;
        width: 24px;
        margin-left: auto;
        font-size: $font-size-small;
        display: flex;
        align-items: center;
        justify-content: center;


        .btn__arrow {
            position: relative;
            height: 15px;
            width: 15px;
            border-bottom: solid 3px $brand01;
            border-right: solid 3px $brand01;
            transform: rotate(-45deg);
            margin-left: -12px;
            z-index: 10;
            transition: $transition-base;
        }

        &:hover {
            .btn__arrow {
                margin-left: -1px;
            }
        }
    }
    &.btn-more {
        color: $gray01;
        padding: 0;
        display: flex;

        .circle-plus {
            transition: all 0.75s ease-in-out;
            display: block;
            position: relative;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            border: solid 2px $brand01;
            margin-right: .5rem;
            transition: all 0.5s ease-in-out;
            
            .horizontal {
                position: absolute;
                background-color: $brand01;
                width: 12px;
                height: 2px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -1px;
                transition: all 0.5s ease-in-out;
            }
            .vertical {
                position: absolute;
                background-color: $brand01;
                width: 2px;
                height: 12px;
                left: 50%;
                margin-left: -1px;
                top: 50%;
                margin-top: -6px;
                transition: all 0.5s ease-in-out;
            }
        }

        &.active {
            .circle-plus {
                transition: all 0.5s ease-in-out;

                .vertical {
                    transition: all 0.5s ease-in-out;
                    transform: rotate(90deg);
                }
                .horizontal {
                    transition: all 0.5s ease-in-out;
                    transform: rotate(90deg);
                    opacity: 0;
                }
            }
        }
    }
}
.btn-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2rem);
    padding: 1.5rem 2rem;
    background: #FFF;
    color: $gray01;
    font-weight: $font-weight-bold;
    border: solid 1px $gray04;
    margin-bottom: 2rem;
    box-shadow: none;
    transition: $transition-base;

    .category__icon {
        display: block;
        height: 56px;
        width: 56px;
        margin-bottom: .75rem;
        transition: $transition-base;

        img {
            width: 100%;
            height: auto;
        }
    }
    &:hover {
        text-decoration: none;
        color: $gray02;
        background: rgba(255,255,255,0.5);
        box-shadow: $box-shadow1;

        .category__icon {
            scale: 1.1;
        }
    }
}
.text-link {
    text-decoration: underline;
    color: $gray02;
}
.reader-btn {
    width: 3.5rem;
    height: 3rem;
    border-radius: 2rem 0 0 2rem;
    background-color: $alert-yellow;
    background-image: url('~img/gevestor/icon-reader.svg');
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 11;
}
.link-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .list__item {
        margin-bottom: 1.15rem;
        line-height: normal;
        font-size: $font-size-small;
        border-bottom: solid 1px $gray04;
        padding-bottom: 1.15rem;

        a {
            color: $gray01;
            display: flex;
            justify-content: space-between;

            .link__arrow {
                position: unset;
                float: unset;
                margin-top: auto;
                margin-bottom: .5rem;
                margin-right: unset;
                flex: 0 0 10px;
            }
        }
    }
}
.link__arrow {
    display: inline-block;
    position: relative;
    float: left;
    height: 10px;
    width: 10px;
    border-bottom: solid 3px $brand01;
    border-right: solid 3px $brand01;
    transform: rotate(-45deg);
    margin-right: 12px;
    margin-top: 8px;
    z-index: 10;
    transition: $transition-base;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.back-to-top {
    background: $brand01;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom:20px;
    right:20px;
    z-index: 10;
    text-align: center;
    transition: $transition-base;
    border-radius: 50%;

    &:hover {
        bottom: 25px;
    }

    .more-arrow {
        display: inline-block;
        height: 12px;
        width: 12px;
        transition: all 150ms ease-in-out;
        position: relative;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(-135deg);
        margin-top: 6px;
    }

    &:hover {
        bottom: 25px;

        .more-arrow {
            margin-top: 4px;
        }
    } 
}
@media (max-width: 559px) {
    .btn {
        max-width: 100%;
        white-space: normal;

        &.btn-primary {
            max-width: 100%;
            white-space: normal;
        }
    }
}
// Responsive
// --------------------------------------------------
