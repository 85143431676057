//
// Type MD
// --------------------------------------------------
@media (max-width: 767px) {
    h1 {
    font-size: $font-size-h1-sm;
    }
    h2 {
        font-size: $font-size-h2-sm;
    }
    h3 {
        font-size: $font-size-h3-sm;
    }
    h4 {
    @include font-title-h4; 
    }
}
// Responsive
// --------------------------------------------------
