//
// Accordion
// --------------------------------------------------
.accordion {
    background: $gray06;
    border-bottom: solid 1px $gray04;

    button.accordion__header, .accordion__header {
        display: block;
        width: 100%;
        position: relative;
        text-align: left;
        @include font-title-h3;
        border: none;
        border-top: solid 1px $gray04;
        padding: 1rem 3rem 1rem 1.5rem;
        font-size: 1rem;
        background: $gray05;
        cursor: pointer;

        &:before, &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            width: 18px;
            height: 2px;
            background: $brand01;
        }
        
        &:after{
            transform: rotate(0deg);
            margin-top: -1px;
            transition: $transition-base;
        }

        &:hover {
            background: $gray05;

            &:before, &:after {
                background: $brand01-ct;
            } 
        }

        &.collapsed {
            background: none;

            &:after{
                transform: rotate(-90deg);
                transition: $transition-base;
            }
        }
    }

    .accordion__content {
        border-top: solid 1px $gray04;
        padding: 1.5rem;
    }

}
// Responsive
// --------------------------------------------------
