@media screen and (max-width: 425px) {

  .productAnchor {
    scroll-margin-top: .5rem;
  }

  .productAnchor.scrolled {
    scroll-margin-top: .5rem;
  }

}



