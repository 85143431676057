//
// PPM Template Variables
// --------------------------------------------------

// Colors
// --------------------------------------------------
$brand01				: #6fb316;
$brand01-ct             : #6fb316;
$brand02                : #fa7647;

$alert-green			: #AFD79B;
$alert-yellow			: #EFDC59;
$alert-red				: #FF0000;

$gray01					: #1a2b3f;
$gray02					: #435c74;
$gray03					: #879aab;
$gray04					: #d4dae0;
$gray05	                : #e7edf0;
$gray06					: #F5F7F8;


// Typography
// --------------------------------------------------
$font-family            : 'Open Sans', sans-serif;
$font-size-base			: 17px;
$line-height-base       : 1.75;
$font-weight-base		: 400;
$font-weight-bold		: 700;

$font-size-small		: 15px;
$font-size-xsmall		: 13px;
$font-size-big			: 20px;

$font-family-display    : 'Merriweather', sans-serif;
$font-weight-display    : 300;
$font-size-h1			: 2.4rem;
$line-height-h1         : 1.5;
$font-size-h2			: 1.7rem;
$font-size-h3			: 1.35rem;
$font-size-h4			: 1rem;

$font-size-h1-md	    : 2rem;
$font-size-h2-md	    : 1.4rem;
$font-size-h3-md	    : 1.2rem;

$font-size-h1-sm		: 1.6rem;
$font-size-h2-sm		: 1.3rem;
$font-size-h3-sm		: 1.3rem;

$font-size-nav          : 16px;

// Transitions
// --------------------------------------------------
$transition-base        : all 0.3s ease-in-out;
$border-radius          : 0;
$box-shadow             : -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
$box-shadow1            : -1px 9px 18px 0px rgba(75, 81, 91, 0.3);