//
// Newsletter SM
// -------------------------------------------------
@media (max-width: 1024px) {
    .newsletter {
        .col-xl-8 {
            padding-left: 15px;
        }
        .col-xl-4 {
            padding-right: 15px;
        }
        .newsletter__order {
            .newsletter__trust {
                padding-left: 3rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                line-height: 1.65;
                background: url(/app/themes/xlib/public/img/icon-trust1.png) left 1rem no-repeat;
                background-size: 2rem auto;
            }

            &.sidebar-sticked {
                top: 2.5rem;
                position: relative;
            }
        }
    }
}
@media (max-width: 991px) {
    .newsletter {
        .col-xl-4 {
            padding-left: 15px;
        }
        .block--teaser {
            padding: 2rem 1rem;
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        .newsletter__order {
            top: 2rem;
            margin-bottom: 3rem; 
        }
        
        padding-top: 1rem;
    }
}