//
// Simplify Template Main Navigation MD
// --------------------------------------------------
@media (max-width: 1400px){
    .header .col-lg-8 {
        justify-content: flex-start;
    }
}


@media (max-width: 991px){

    .hamburger-menu-container{
        display: flex;
        position: absolute;
        top: 1.2rem;
        left: .5rem;
    }
    #check{
        display: block;
        z-index: 1002;
    }
    .mainmenu {
        .buttons--desk, .cta-desk {
            display: none;
        }

        .nav-btn {
            position: fixed;
            height: calc(100vh - 74px);
            top: 74px;
            width: 100%;
            background-color: $gray05;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            overflow-x: hidden;
            overflow-y: auto;
            left: 100%;
            z-index: 90;

            .nav-links {
                flex: initial;
                width: 100%;
    
                > ul {
                    flex-direction: column;
                    padding: 0;
                    margin: 0;

                    .nav-link {
                        width: 100%;
                        border-bottom: solid 1px $gray04;
                        padding: 0;
                        position: relative;
    
                        > a {
                            display: block;
                            line-height: 1;
                            padding: 1.25rem 1rem;
                            color: $gray02;
                            width: 80%;
                            
                            &:before, &:after {
                                display:block;
                                position: absolute;
                                top: 1rem;
                                left: .35rem;
                                content: '';
                                height: 28px;
                                width: 3px;
                                transition: $transition-base;
                            }
                            &:after {
                                height: 0;
                                background: $brand01; 
                            }

                            &:hover {
                                text-decoration: none;
                            }

                            &:focus {
                                outline: none;
                                color: $brand01;
                                &:after { 
                                    animation: linedown .5s forwards;   
                                }
                            }
                        }
                    }
                }
            }
            .btn--cta {
                display: none;
            }
            .menu__buttons.buttons--mobile {
                margin-top: 2rem;
                padding: 0 1rem;

                .icon-links{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            } 
        }

        #check:checked ~ .nav-btn {
            left: 0;
        }

        .dropdown, .dropdown.second {
            position: initial;
            top: initial;
            left: initial;
            transform: initial;
            opacity: 1;
            pointer-events: auto;
            width: 100%;
            padding: 0;
            background-color: $gray06;
            display: none;

            &:before {
                content: '';
                display: block;
                height: 14px;
                width: 14px;
                background: $gray06;
                position: absolute;
                left: 1rem;
                top: -7px;
                transform: rotate(45deg);
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            .dropdown-link {
                position: relative;
                border-top: solid 1px $gray05;

                > a {
                    display: block;
                    background-color: transparent;
                    padding: 1.25rem 1rem;
                    line-height: 1;
                    color: $gray02;
                    transition: $transition-base;
                    width: 80%;

                    &:hover {
                        outline: none;
                        text-decoration: none;
                        color: $gray03;
                    }
                    &:focus {
                        outline: none;
                    }
                }

                &:first-child {
                    border-top: none;
                }
            }
        }
        .dropdown.second {
            top: 0;
            left: 100%;
            cursor: pointer;
            background-color: #fff;

            &:before {
                background: #fff;
            }
        }

        .nav-link.open > .dropdown,
        .dropdown-link.open > .dropdown {
            transform: translate(0, 0);
            opacity: 1;
            pointer-events: auto;
        }
          
        .nav-link.open > .dropdown,
        .dropdown-link.open > .dropdown {
            display: block;
        }

        .nav-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 40px;
            width: 40px;
            right: .75rem;
            top: .7rem;
            cursor: pointer;

            .toggle-icon {
                display: block;
                height: 12px;
                width: 12px;
                border-top: solid 2px $gray03;
                border-right: solid 2px $gray03;
                transform: rotate(135deg);
            }

            &:hover {
                .toggle-icon {
                    border-top: solid 2px $brand01;
                    border-right: solid 2px $brand01;
                }
            }
        }

        .open {
            > .nav-toggle {
                .toggle-icon {
                    border-top: solid 2px $brand01;
                    border-right: solid 2px $brand01;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    .noscript {
        .mainmenu {
            .nav-link:hover > .dropdown,
            .dropdown-link:hover > .dropdown {
                transform: translate(0, 0);
                opacity: 1;
                pointer-events: auto;
            }
              
            .nav-link:hover > .dropdown,
            .dropdown-link:hover > .dropdown {
                display: block;
            }

        }
    }
}
@keyframes animation {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}