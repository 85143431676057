//
// display
// --------------------------------------------------
.display {
    .block__info {
        .block__short {
            max-width: 800px;
            margin: 0 auto 3rem;
        }
    }
    .display__box {
        padding: 2rem;

        a {
            color: $brand01;
        }
        &.box--gray {
            background: $gray06;
        }
        .box__icon {
            margin-bottom: 2rem;
            height: 74px;

            img {
                max-height: 100%;
            }
        }
    }
    
}
// Responsive
// --------------------------------------------------
@import "responsive/display-sm";