.trust {
    &.trust--stage {
        height: 100px;
        margin-top: -75px;
    }

    .trust__elements {
        font-size: 14px;
        line-height: normal;

        .col-sm-4 {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-right: solid 1px $gray04;

            &:last-child {
                border-right: none;
            }
        }
        .trust__element {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .trust__title {
                font-weight: bold;
            }
            .trust__icon {
                height: 36px;
                width: 36px;
                margin-right: .5rem;
                overflow: hidden;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }

    &.trust--sidebar {
        margin: 3rem 0;

        .col-sm-4 {
            align-items: flex-start;
        }
        .trust__element {
            text-align: center;
            justify-content: center;

            .trust__title {
                margin-bottom: .75rem;
            }
            .trust__icon {
                margin-bottom: .4rem;
                height: 32px;
                width: 32px;
                margin-right: 0;
            }
        }
    }
}
// Responsive
// --------------------------------------------------
@import "responsive/trust-md";
@import "responsive/trust-sm";