//
// Teaser MD
// --------------------------------------------------
@media (max-width: 767px) {
    .teaser--right {
        .block__img {
            margin-bottom: 2.5rem;
        }
        .block__info {
            text-align: center;
        }
        .teaser__row {
            width: 100%;
            margin-left: 0;

            .teaser {
                box-shadow: none;

                &:hover {
                    box-shadow: none;
                }

                .teaser__inner {
                    padding: 0 2rem;
                }
            }
        }
    }
    .teaser--left {
        .teaser__row {
            width:100%;
            margin-right: 0;
        }
    }
    .card {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
    }
    .teaser {
        height: auto;
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;

        .teaser__inner {
            padding-bottom: .5rem;
        }

        &.teaser--horizontal {
            max-width: unset;
            .teaser__title {
                margin-bottom: 0;
            }
            .teaser__short {
                display: none;
            }
            &.teaser--100 {
                height: auto;
            }
        }
    }
    .teaser--img-only {
        font-size: $font-size-small;
        margin-bottom: 2.5rem;
        .teaser__img {
            height: 140px;
            width: 140px;
        }
    }
    .teaser--wall {
        .block__info {
            text-align: center;
        }
    }
    .col-md-4 {
        .teaser {
            margin-bottom: 2rem;
        }
    }
}