//
// Carousel
// --------------------------------------------------
.owl-carousel {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
}
.owl-nav {
	position: absolute;
	top: 50%;
	margin-top: -60px;
	margin-left: 0;
	width: calc(100% + 140px);
	display: flex;

	&.disabled {
		display: none;
	}

	.owl-prev, .owl-next {
		height: 40px;
		width: 40px;
		border: none;
		background: url("~img/gevestor/arrow-right-gray.svg") center center no-repeat;
		background-size: 100% auto;

		span {
			display: none;
		}
	}
	.owl-prev {
		transform: rotate(180deg);
	}
	.owl-next {
		margin-left: auto;
	}
}
.owl-dots {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0;

	.owl-dot {
		border: none;
		background: $gray04;
		border: solid 2px $gray04;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		margin: 0 .25rem;

		&.active {
			background: none;
			border: solid 2px $brand01;
		}
		
	}

	&.disabled {
		display: none;
	}
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
	transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
}
.owl-carousel .owl-stage {
	display: flex;
	
	.item {
		height: 100%;
	}
}
.teaser-item {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
	
	.teaser {
		position: relative;
		overflow: hidden;
		/* margin-bottom: 80px; *//*This is optional*/
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
}

// Responsive
// --------------------------------------------------
@import "responsive/carousel-sm";