//
// Article
// --------------------------------------------------
.article__start {
    margin-bottom: 2rem;

    .articlecat__top {
        padding-right: 3rem;
    }
    .article__chart {
        margin-bottom: 2rem;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 2rem;

        li {
            margin-top: .35rem;
            padding-left: 1.2rem;
            position: relative;

            &:before {
                content: '•';
                font-size: 30px;
                color: $gray03;
                display: inline-block;
                height: 1rem;
                width: 1rem;
                position: absolute;
                left: 0;
                top: -4px;
                line-height: 37px;
            }
        }
    }
	.article__info {
        font-size: $font-size-small;
        color: $gray03;
		border-top: solid 1px $gray04;
		border-bottom: solid 1px $gray04;
		padding: .5rem 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
		margin-top: 2rem;
		margin-bottom: 1rem;

        .article__cat {
            background: $gray02;
            padding: .2rem 1rem;
            border-radius: 15px;
            position: relative;
            cursor: pointer;
            transition: $transition-base;

            .cat__text {
                position: relative;
                color: #fff;
                z-index: 2;
                transition: $transition-base;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 0;
                border-radius: 15px;
                background-color: $gray03;
                transition: $transition-base;
                z-index: 1;
            }

            &:hover {
                text-decoration: none;

                &:before {
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: $transition-base;
                }

                .cat__text {
                    color: #fff;
                    transition: $transition-base;
                }
            }
        }
        .articlecat__author {
            margin-left: auto;
            display: inline-block;
            color: $gray02;

            &:hover {
                text-decoration: none;
                color: $gray01;
            }
        }
    }
    .article__time {
        font-size: $font-size-small;
        color: $gray03;
        padding-left: 1.4rem;
        background: url(~img/gevestor/icon-time.svg) left 2px no-repeat;
        background-size: auto 18px;
    }
	.article__more {
		margin: 2rem 0 1rem;
        display: flex;
        align-items: center;

        .more__buttons {
            margin-left: auto;
        }
	}
}
ins.d-inline-block {
    margin-top: 2rem;
    iframe {
        max-width: 100%;
    }
}
.article__editor {
    h1 {
        margin-top: .6rem;
        margin-bottom: 2rem;
    }
    h2 {
        margin-bottom: 1rem;
        margin-top: 2.5rem;
        scroll-margin-top: 8rem;
    }
    h3 {
        margin-bottom: .75rem;
        margin-top: 2rem;
    }
    a {
        color: $gray02;
        text-decoration: underline;
        &:hover {
            color: $brand01;
            text-decoration: none;
        }
    }
    p {
        &.has-large-font-size {
            @include font-title-h3;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 2rem;

        li {
            margin-top: .35rem;
            padding-left: 1.2rem;
            position: relative;

            &:before {
                content: '•';
                font-size: 30px;
                color: $gray03;
                display: inline-block;
                height: 1rem;
                width: 1rem;
                position: absolute;
                left: 0;
                top: -4px;
                line-height: 37px;
            }
        }
        &.is-style-check, &.check {
            li {
                padding-left: 1.5rem;

                &:before {
                    content: '';
                    height: 1.125rem;
                    width: 1.125rem;
                    top: 5px;
                    background: url("~img/gevestor/icon-check.svg");
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-position: left center;
                }
            }
        }
        &.is-style-arrow {
            li {
                padding-left: 1.4rem;

                &:before {
                    content: '';
                    height: .9rem;
                    width: .9rem;
                    top: 8px;
                    background: url("~img/gevestor/icon-arrow-right.svg");
                    background-repeat: no-repeat;
                    background-size: 100%;
                    background-position: left center;
                }
            }
        }
    }

    ol {
        list-style: none;
        counter-reset: step-counter;
        padding: 0;

        li {
            padding-left: 25px;
            &::before {
                counter-increment: step-counter;
                content: counter(step-counter)'. ';
                font-weight: $font-weight-bold;
                color: $gray02;
                margin-left: -25px;
                margin-right: 6px;
              }
        }

        &.is-style-roman {
            li {
                &::before {
                    content: counter(step-counter, upper-roman) ". ";
                }
            }
        }
    }
    .wp-block-image {
        figure {
            &.aligncenter {
                text-align: center;
            }

            figcaption {
                background: $gray06;
                padding: 1rem 1.5rem;
                font-size: $font-size-small;
                line-height: 1.5;
                text-align: left;
            }
        }
    }
    .embed-responsive {
        margin: 0 0 2rem;
    }

    .wp-block-embed {
        clear: both;
        margin: 0 0 1em;

        .wp-block-embed__wrapper {
            position: relative;

            &:before {
                content: "";
                display: block;
                padding-top: 50%;
            }
        }

        &.wp-has-aspect-ratio, &.is-type-video {
            iframe {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
                max-width: 100%;
            }
        }

        &.wp-embed-aspect-16-9 {
            .wp-block-embed__wrapper {
                &:before {
                    padding-top: 56.25%;
                }
            }
        }

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
        }
    }

    .wp-block-columns {
        display: flex;
        margin-bottom: 1.75em;
        box-sizing: border-box;
        flex-wrap: wrap;
        /**
        * All Columns Alignment
        */
    }
    @media (min-width: 782px) {
        .wp-block-columns {
        flex-wrap: nowrap;
        }
    }
    .wp-block-columns.has-background {
        padding: 1.25em 2.375em;
    }
    .wp-block-columns.are-vertically-aligned-top {
        align-items: flex-start;
    }
    .wp-block-columns.are-vertically-aligned-center {
        align-items: center;
    }
    .wp-block-columns.are-vertically-aligned-bottom {
        align-items: flex-end;
    }

    .wp-block-column {
        flex-grow: 1;
        min-width: 0;
        word-break: break-word;
        overflow-wrap: break-word;
        /**
        * Individual Column Alignment
        */
    }
    @media (max-width: 599px) {
        .wp-block-column {
        flex-basis: 100% !important;
        }
    }
    @media (min-width: 600px) and (max-width: 781px) {
        .wp-block-column:not(:only-child) {
        flex-basis: calc(50% - 1em) !important;
        flex-grow: 0;
        }
        .wp-block-column:nth-child(even) {
        margin-left: 2em;
        }
    }
    @media (min-width: 782px) {
        .wp-block-column {
        flex-basis: 0;
        flex-grow: 1;
        }
        .wp-block-column[style*=flex-basis] {
        flex-grow: 0;
        }
        .wp-block-column:not(:first-child) {
        margin-left: 2em;
        }
    }
    .wp-block-column.is-vertically-aligned-top {
        align-self: flex-start;
    }
    .wp-block-column.is-vertically-aligned-center {
        align-self: center;
    }
    .wp-block-column.is-vertically-aligned-bottom {
        align-self: flex-end;
    }
    .wp-block-column.is-vertically-aligned-top, .wp-block-column.is-vertically-aligned-center, .wp-block-column.is-vertically-aligned-bottom {
        width: 100%;
    }


    .wp-block-media-text {
        direction: ltr;
        display: grid;
        grid-template-columns: 50% 1fr;
        grid-template-rows: auto;
        margin-bottom: 2rem;
        &.is-style-gray {
            background: $gray06;
        }
    }
    .wp-block-media-text.has-media-on-the-right {
        grid-template-columns: 1fr 50%;
    }

    .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
    .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
        align-self: start;
    }

    .wp-block-media-text .wp-block-media-text__content,
    .wp-block-media-text .wp-block-media-text__media,
    .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
    .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media {
        align-self: center;
    }

    .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
    .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
        align-self: end;
    }

    .wp-block-media-text .wp-block-media-text__media {
        /*!rtl:begin:ignore*/
        grid-column: 1;
        grid-row: 1;
        /*!rtl:end:ignore*/
        margin: 0;
    }

    .wp-block-media-text {
        .wp-block-media-text__content {
            direction: ltr;
            grid-column: 2;
            grid-row: 1;
            padding: 0 0 0 2rem;
            word-break: break-word;
        }

        &.has-media-on-the-right {
            .wp-block-media-text__content {
                padding: 0 2rem 0 0;
            }
        }
        &.is-style-gray {
            .wp-block-media-text__content {
                padding: 2rem;
            }
        }
    }

    .wp-block-media-text.has-media-on-the-right .wp-block-media-text__media {
        /*!rtl:begin:ignore*/
        grid-column: 2;
        grid-row: 1;
        /*!rtl:end:ignore*/
    }

    .wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
        /*!rtl:begin:ignore*/
        grid-column: 1;
        grid-row: 1;
        /*!rtl:end:ignore*/
    }

    .wp-block-media-text__media img,
    .wp-block-media-text__media video {
        max-width: unset;
        width: 100%;
        vertical-align: middle;
        height: auto;
    }

    .wp-block-media-text.is-image-fill .wp-block-media-text__media {
        height: 100%;
        min-height: 250px;
        background-size: cover;
    }

    .wp-block-media-text.is-image-fill .wp-block-media-text__media > a {
        display: block;
        height: 100%;
    }

    .wp-block-media-text.is-image-fill .wp-block-media-text__media img {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

      /*
      * Here we here not able to use a mobile first CSS approach.
      * Custom widths are set using inline styles, and on mobile,
      * we need 100% width, so we use important to overwrite the inline style.
      * If the style were set on mobile first, on desktop styles,
      * we would have no way of setting the style again to the inline style.
      */
    @media (max-width: 600px) {
        .wp-block-media-text.is-stacked-on-mobile {
          grid-template-columns: 100% !important;
        }
        .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
          grid-column: 1;
          grid-row: 1;
        }
        .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
          grid-column: 1;
          grid-row: 2;
        }
    }

    table {
        border-top: solid 2px $gray04;
        border-bottom: solid 1px $gray04;
        width: 100%;

        tr {
            border-bottom: solid 1px $gray04;

            td, th {
                padding: .5rem 1rem .5rem 0;
                vertical-align: top;

                &.has-text-align-right {
                    text-align: right;
                }
                &.has-text-align-left {
                    text-align: left;
                }
                &.has-text-align-center {
                    text-align: center;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        thead {
            tr {

                &:last-child {
                    border-bottom: solid 2px $gray04;
                }
            }
        }
        tfoot {
            tr {
                border-top: solid 2px $gray04;
            }
        }
    }
    .wp-block-table {
        overflow-x: hidden;
        overflow-x: auto;
        
        figcaption {
            font-size: $font-size-small;
            padding: .5rem 0;
            color: $gray02;
        }
        table {
            border-top: solid 2px $gray04;
            border-bottom: solid 1px $gray04;
            width: 100%;

            tr {
                border-bottom: solid 1px $gray04;

                td, th {
                    padding: .5rem 1rem .5rem 0;
                    vertical-align: top;

                    &.has-text-align-right {
                        text-align: right;
                    }
                    &.has-text-align-left {
                        text-align: left;
                    }
                    &.has-text-align-center {
                        text-align: center;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            thead {
                tr {

                    &:last-child {
                        border-bottom: solid 2px $gray04;
                    }
                }
            }
            tfoot {
                tr {
                    border-top: solid 2px $gray04;
                }
            }
        }

        &.is-style-stripes {
            table {
                tbody {
                    tr {
                        border-bottom: none;

                        &:nth-child(2n+1) {
                            background: $gray06;
                        }
                        td, th {
                            padding: .5rem .75rem;
                            vertical-align: top;
                        }
                    }
                }
                thead {
                    tr {
                        border-bottom: solid 2px $gray04;

                        td, th {
                            padding: .5rem .75rem;
                            vertical-align: top;
                        }
                    }
                }
                tfoot {
                    tr {
                        border-top: solid 2px $gray04;
                    }
                }
            }
        }

        &.is-style-checklist {
            width: 100%;

            thead {
                tr {
                    background: $gray06;

                    th:first-child {
                        padding-left: 50px;

                        &:before {
                            content: ' ';
                            display: inline-block;
                            height: 26px;
                            width: 26px;
                            border: solid 2px $gray04;
                            border-radius: 6px;
                            position: absolute;
                            left: 5px;
                            background: url(~img/gevestor/icon-check-gray.svg) center center no-repeat;
                            background-size: 80%;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &:first-child {
                            cursor: pointer;
                            padding-left: 50px;
                            position: relative;

                            &:before {
                                content: ' ';
                                display: inline-block;
                                height: 26px;
                                width: 26px;
                                border: solid 1px $gray02;
                                border-radius: 6px;
                                position: absolute;
                                left: 5px;
                            }


                            &.checked {
                                &:before {
                                    background: url(~img/gevestor/icon-check.svg) center center no-repeat;
                                    background-size: 80%;
                                }
                            }
                        }
                    }

                    &:hover {
                        td {
                            background-color: $gray06;
                            border-bottom: solid 1px $gray04;

                            &:first-child {
                                background-color: $gray06;
                                border-bottom: solid 1px $gray04 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .wp-block-quote {
        border-top: solid 2px $gray04;
        border-bottom: solid 2px $gray04;
        padding: 2rem 0 1rem 4rem;
        margin: 2rem 0 2rem;
        text-align: left;
        font-style: italic;
        font-size: 20px;
        color: $gray02;
        position: relative;

        &:before {
            content: '';
            display: inline-block;
            height: 4rem;
            width: 4rem;
            background: #fff url(~img/gevestor/icon-quote.svg) left top no-repeat;
            background-size: 70% auto;
            position: absolute;
            left: 0;
            top: -3px;
        }

        .article__quote__source, cite {
            font-style: normal;
            font-size: $font-size-small;
        }
    }

    .box {
        padding: 2rem 2rem 1rem;
        background: $gray06;
        margin: 2rem 0 2rem;
        position: relative;

        .message-heading {
            margin-top: 0;
            margin-bottom: 1rem;
            font-size: $font-size-h3;
            font-family: $font-family-display;
            font-weight: $font-weight-display;
            color: $brand01;
        }
        h3 {
            margin-top: 0;
            margin-bottom: 1rem;
            color: $brand01;
        }
        h2 {
            margin-top: 0;
            margin-bottom: 1rem;
            color: $brand01;
            font-size: $font-size-h3;
        }
        p {
            margin-top: 0;
            margin-bottom: 1rem;
        }
        &.box--tipp, &.box--attention {
            &:before {
                content: '';
                height: 4rem;
                width: 3rem;
                position: absolute;
                top: 2rem;
                left: 1.5rem;
                background: url(~img/gevestor/icon-tipp.svg) center top no-repeat;
                background-size: auto 100%;
            }

        }
        &.box--attention {
            &:before {
                background: url(~img/gevestor/icon-attention.svg) center top no-repeat;
                background-size: auto 100%;
            }
        }
    }
    .is-style-box--attention, .is-style-box--info, .is-style-box--tipp {
        .box {
            padding: 2rem 2rem 1rem 6rem;

            &:before {
                content: '';
                height: 4rem;
                width: 3rem;
                position: absolute;
                top: 2rem;
                left: 1.5rem;
                background: url(~img/gevestor/icon-info.svg) center top no-repeat;
                background-size: auto 90%;
            }
        }
    }
    .is-style-box--info {
        .box {
            &:before {
                background: url(~img/gevestor/icon-info.svg) center top no-repeat;
                background-size: auto 90%;
            }
        }
    }
    .is-style-box--tipp {
        .box {
            &:before {
                background: url(~img/gevestor/icon-tipp.svg) center top no-repeat;
                background-size: auto 90%;
            }
        }
    }
    .is-style-box--attention {
        .box {
            &:before {
                background: url(~img/gevestor/icon-attention.svg) center top no-repeat;
                background-size: auto 90%;
            }
        }
    }
    .is-style-box--dark {
        .box {
            background: $gray02;
            color: #fff;

            h3 {
                color: #fff;
            }
        }
    }

    // Block Testimonial
    // --------------------------------------------------
    .wp-block-reichweiten-testi-block {
        margin: 2.5rem 0 2.5rem 1rem;
        position: relative;

        .testi {
            height: auto;
            background: $gray06;
            position: relative;
            padding: 2rem 2rem 1rem;
            display: flex;
            align-items: center;

            .wp-block-image {
                height: 200px;
                width: 200px;
                min-width: 200px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 2rem 1rem 0;
                border: solid 1px $gray02;

                img {
                    height: 100%;
                    width: 100%;
                    position: relative;
                }
            }
    
            .testi__content {
            
                .testi__name {
                    font-weight: bold;

                    a {
                        color: $brand01;
                        text-decoration: none;

                        &:hover {
                            color:  $gray01;
                        }
                    }
                }
                .testi__position {
                    color: $gray02;
                }
                .testi__quote {
                    margin-top: 1.5rem;
                }
            }	
        }
        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            background: $brand01;
            position: absolute;
            top: -1rem;
            left: -1rem;
            z-index: 0;
        }
    }

    .wp-block-gevestor-relatedlinks-block {
        padding: 2rem;
        margin: 2rem 0;
        background: $gray06;

        .related__title {
            text-align: center;
            @include font-title-h3;
            margin-bottom: 2rem;
        }

        .related_item {
            border-top: solid 1px $gray04;
            line-height: normal;

            .related_link {
                display: block;
                position: relative;
                color: $gray01;
                text-decoration: none;
                transition: $transition-base;
                padding: .75rem 0 .75rem 1.3rem;

                .more-arrow {
                    height: 9px;
                    width: 9px;
                    transition: $transition-base;
                    position: relative;
                    border: solid $gray03;
                    border-width: 0 2px 2px 0;
                    transform: rotate(-45deg);
                    margin-right: 12px;
                    margin-top: 7px;
                    position: absolute;
                    left: 0;
                }
                .more-arrow:before {
                    content: '';
                    display: inline-block;
                    width: 0;
                    height: 2px;
                    background: $gray03;
                    transition: $transition-base;
                    position: absolute;

                    transform: rotate(45deg);
                }

                &:hover {
                    text-decoration: none;
                    padding-left: 1.5rem;

                    .more-arrow {
                        left: 7px;
                        border: solid $brand01;
                        border-width: 0 2px 2px 0;
                    }

                    .more-arrow:before {
                        width: 14px;
                        margin-left: -4px;
                        margin-top: 2px;
                        background: $brand01;
                    }
                }
            }
        }
    }
    .teaser-box {
        background: $gray06;
        padding: 2rem 2rem .5rem;
        margin: 2rem 0 2.5rem;

        h2, h3 {
            margin-top: 0;
        }

        .wp-block-columns {
            justify-content: center;
            margin-top: 2rem;

            .wp-block-column {
                max-width: calc(25% - 1rem);
                margin: 0 .5rem;
            }
        }
        .teaser {
            background: #fff;
            box-shadow: none;
            height: 100%;
            margin-bottom: 0;

            .teaser__inner {
                padding: 0;
                height: 100%;
            }
            .teaser__link {
                display: block;
                height: 100%;
                text-decoration: none;
            
                .wp-block-image {
                    height: 120px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    img {
                        width: 100%; 
                        height: auto;
                        transition: $transition-base;
                    }
                    figcaption {
                        display: none;
                    }
                }

                .teaser__title {
                    padding: .5rem 1rem 0;
                    color: $gray02;
                    font-size: 1rem;
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    .wp-block-image {
          
                        img {
                            transform: scale(1.2);
                            transition: $transition-base;
                        }
                    }
                    .teaser__title {
                        text-decoration: none;

                        &:after {
                            width: 100%;
                        }
                    } 
                }
            }
        }

        &.is-style-box--brand {
            background: $brand01;
            color: #fff;

            h2, h3, h4, a {
                color: #fff;
            }
            ul {
                li {
                    &:before {
                        filter: invert(100%) saturate(0) brightness(1000%);
                    }
                }
            }
            .teaser {
                .wp-block-image {
                    background: $brand02;
                }
                .teaser__link {
                    .teaser__title {
                        color: #000;
                    }
                }
            }
        }
    }
}
.article__full {
    padding-bottom: 4rem;

    .article__start {
        padding-top: .75rem;

        .article__info {
            margin-bottom: 0;

            .article__time {
                margin-left: auto;
                margin-right: auto;
            }

            .articlecat__author {
                margin-left: 0;
            }
        }
    }
    .article__header-image {
		position: relative;
		width: 100%;
		overflow: hidden;
		padding-bottom: 52.5%;

		img {
			position: absolute;
			width: 100%;
			height: auto;
		}

		.header-image__copy {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-color: rgba(255,255,255,0.7);
			font-size: 10px;
			line-height: normal;
			padding: .5rem 1rem;
		}
	}
    p.has-text-align-center {
        text-align: center;
    }
}

.category__editor {
    h2 {
        scroll-margin-top: 8rem;
    }
}

.page {
    .article__full {
        .article__header-image {
            margin-bottom: 2rem;
        }
    }
    [data-nss-snippet="Datenschutz_Wirtschaftswissen"] ol {
        list-style: none !important;

        li {
            list-style: none !important;
            &::before {
                content: counter(step-counter, upper-roman) ". ";
            }
        }
    }
}
.article__main {
    padding-top: 1.2rem;
}
.article__tools {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2.5rem;
}
.article__share {
    font-size: $font-size-small;
    z-index: 10;
    display: flex;
    align-items: center;

    .share__button {
        display: none;
    }

    .share__items {
        display: flex;
        width: auto;
    }
    .share__item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        width: 28px;
        flex: 0 0 28px;
        background-color: $gray02;
        border-radius: 5px;
        margin-right: .4rem;
        transition: $transition-base;
        cursor: pointer;

        svg {
            fill: #fff;
            width: 20px;
            transition: $transition-base;
        }

        &:hover {
            background-color: $brand01;
        }
        &.item--copy {
            position: relative;

            .copy-confirm {
                position: absolute;
                font-size: $font-size-xsmall;
                background: $alert-green;
                color: $gray01;
                font-weight: $font-weight-bold;
                padding: .25rem .75rem;
                white-space: nowrap;
                top: -2rem;
                right: 0;
                display: none;
            }
        }
    }
}
.article__author {
    padding: 2rem;
    background: $gray06;
    margin: 2rem 0;

    .col-md-8, .col-md-4 {
        align-items: center;
        display: flex;
        padding-top: 1rem;
        padding-bottom: 1rem;

        p {
            margin-bottom: 0;
        }
    }
    .order-12 {
        padding-top: 1rem;
    }
    .author__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: solid 1px $gray04;
        padding-bottom: .75rem;
        margin-bottom: 1rem;
        @include font-title-h3;
        transition: $transition-base;

        .link__arrow {
            float: none;
            margin-bottom: .5rem;
            margin-left: .75rem;
            height: 13px;
            width: 13px;
            transition: $transition-base;
        }
        &:hover {
            text-decoration: none;
            color: $gray01;
            padding-left: .5rem;
        }
    }

    .author__img {
        position: relative;
        width: 85%;
        margin-left: 5%;
        border: solid 3px $gray04;
        border-radius: 50%;
        overflow: hidden;
        max-width: 200px;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
    .author__newsletter {
        border-top: solid 1px $gray04;
        padding-top: 1rem;

        .banner__content {
            margin: 1rem 0 1.5rem;
            font-size: 1rem;
            ul {
                li {
                    margin: .3rem 0;
                    padding-left: 1.75rem;

                    &:before {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
    }

}


.article__state {
    background: #fff;
    margin-bottom: .5rem;
    top: 118px;

    .article__nav {
        display: none;
    }
}
progress {
	left: 0;
	top: 70px;
	width: 100%;
	height: 3px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	background-color: $gray05;
	color: $brand01;
	z-index: 10;
}
progress::-webkit-progress-bar {
	background-color: $gray05;
	z-index: 10;
}
progress::-webkit-progress-value {
	background-color: $brand01;
	z-index: 10;
}
progress::-moz-progress-bar {
	background-color: $brand01;
	z-index: 10;
}
.progress-container {
	width: 100%;
	background-color: $gray05;
	top: 0;
	left: 0;
	height: 3px;
	display: block;
	z-index: 10;
}
.progress-bar {
	background-color: $brand01;
	width: 0%;
	display: block;
	height: inherit;
	z-index: 10;
}

.article__nav {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
}
.anker__title, .anker__toggle {
    text-transform: uppercase;
    background: $gray06;
    padding: 1rem 2rem;
}
.anker__nav {
    background: $gray06;
    overflow: auto;
    max-height: 500px;
    transition: max-height 0.75s ease-in;

    .anker__inner {
        padding: 0 2rem 1.5rem;
        max-height: 100%;
    }

    &.open {
        height: auto;
    }
    ul {
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;
    }

    .anker__item {
        border-bottom: solid 1px $gray04;
        padding: .5rem 0 !important;
        font-size: $font-size-small;
        margin: 0;

        &:before {
            content: none;
        }

        &:first-child {
            border-top: solid 1px $gray04;
        }
        &:last-child {
            border-bottom: none;
        }

        .anker__link {
            display: block;
            color: $gray02;
            padding-left: 20px;
            transition: $transition-base;
            position: relative;

            .more-arrow {
                height: 8px;
                width: 8px;
                transition: $transition-base;
                position: absolute;
                top: 0;
                left: 0;
                border: solid $gray03;
                border-width: 0 2px 2px 0;
                transform: rotate(-45deg);
                margin-right: 12px;
                margin-top: 9px;
            }

            &:hover, &:focus {
                color: $gray01;
                text-decoration: none;
                padding-left: 25px;

                .more-arrow {
                    left: 5px;
                    border-color: $brand01;
                }
            }
            &:focus {
                outline: none;

                &:after {
                    width: 100%;
                }
            }
        }
    }
}
.anker__toggle {
    .circle-plus {
        height: 24px;
        width: 24px;
        font-size: 1em;
        position: absolute;
        right: 2rem;
        top: 1.2rem;
        transition: all 0.75s ease-in-out;
        display: none;

        .circle {
            position: relative;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            border: solid 2px $brand01;

            .horizontal {
                position: absolute;
                background-color: $brand01;
                width: 12px;
                height: 2px;
                left: 50%;
                margin-left: -6px;
                top: 50%;
                margin-top: -1px;
                transition: all 0.5s ease-in-out;
            }
            .vertical {
                position: absolute;
                background-color: $brand01;
                width: 2px;
                height: 12px;
                left: 50%;
                margin-left: -1px;
                top: 50%;
                margin-top: -6px;
                transition: all 0.5s ease-in-out;
            }
        }
    }

    &.open {
        outline: 0;
        .circle-plus {
            opacity: 1;
            .vertical {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
            }
            .horizontal {
                transition: all 0.5s ease-in-out;
                transform: rotate(90deg);
                opacity: 0;
            }
        }
    }
}
.sidebar-sticked {
    top: 118px;
    .anker__toggle {
        cursor: pointer;
        margin-bottom: -.5rem;
        text-transform: uppercase;
        position: relative;

        .circle-plus {
            display: block;
        }
    }
    .anker__nav {
        max-height: 0;
        transition: max-height 0.75s ease-out;

        &.open {
            max-height: 1500px;
            transition: max-height 0.75s ease-in;
        }
    }


}
.info-text {
	margin: 3rem 0 4rem;
	font-size: calc(.85 * #{$font-size-base});
	
	h1 {
		font-size: calc(.85 * #{$font-size-h1});
	}
	h2 {
		font-size: calc(.85 * #{$font-size-h2});
	}
	h3 {
		font-size: calc(.85 * #{$font-size-h3});
	}
	h1, h2, h3 {
		color: $gray02;
	}
	p, ul {
		font-size: calc(.85 * #{$font-size-base});
	}
}
// Responsive
// --------------------------------------------------
@import "responsive/article-sm";
