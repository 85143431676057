//
// Newsletter
// -------------------------------------------------
.newsletter {
    .col-xl-4 {
        padding-left: 3rem;
    }
    .block__title {
        font-size: 1.6rem;
    }
    .block--teaser {
        background: $gray06;
        padding: 2rem 2rem;
    }
    .newsletter__filter {
        margin-bottom: 1rem;

        .filter__item {
            margin: 0 .25rem 1rem;
            padding: .3rem .75rem;

            &:before {
                content: none;
            }

            .item__text {
                margin: 0;
                color: $gray02;
                padding-left: 26px;

                .helper {
                    top: 5px;
                    height: 16px;
                    width: 16px;
                }
            }

            &:hover {
                color: $brand01;

                .item__text {
                    color: $brand01;

                    .helper {
                        border-color: $brand01;
                    }
                }
            }
        }
        
        .close-icon {
            position: relative;
            top: auto;
            left: auto;
            vertical-align: middle;
        }
    }
    .filter__action {
        display: flex;
        align-items: center;
        justify-content: center;

        .reset-filter {
            display: flex;
            margin-bottom: 0;
            margin-left: 2rem;
            align-items: center;
            position: relative;
            padding-right: 2rem;

            .close-icon {
                top: 3px;
            }
        }
    }
    .newsletter__order {
        padding: 2rem 1.5rem;
        background: $gray02;
        color: #fff;
        top: 150px;

        .error-wrapper {
            max-width: 820px;
            margin: 0 auto;
            text-align: center;

            .error {
                color: $gray02 !important;
                border: solid 2px $brand02;
                font-size: .85rem;
                text-align: center;  
                background-color: rgba(255,255,255,.93);
                padding: .5rem 1rem;
                margin-bottom: 1rem;
            }
            .success {
                color: $gray02 !important;
                border: solid 2px $alert-green;
                font-size: .85rem;
                text-align: center;
                background-color: rgba(255,255,255,.93);
                padding: .5rem 1rem;
                margin-bottom: 1rem;
            }
        }

        .block__title {
            color: #fff;
            font-size: 1.45rem;
        }
        .list__scroll {
            max-height: 160px;
            overflow-y: auto;
            margin: 0 0 1rem;
            padding-right: 1rem;
        }
        #NL_List {
            list-style: none;
            padding-left: 0;

            li {
                margin-top: .35rem;
                padding-left: 1.45rem;
                position: relative;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 1.3rem;
                    width: 1.3rem;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    background: url("~img/gevestor/icon-check.svg");
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-position: left center;
                }
                .close-icon {
                    transform: scale(.8);
                    top: 2px;

                    .horizontal, .vertical {
                        background: #fff;
                    }
                }
            }
        }
        .btn--cta {
            white-space: unset;
            margin-bottom: 1rem;
        }

        .form-control {
            padding: 0.75rem 1rem;
            text-align: center;
        }
        .btn-primary {
            width: 100%;
            margin-top: .75rem;
        }
        .nl__trust {
            padding-left: 4rem;
            padding-top: 1.5rem;
            line-height: 1.65;
            background: url("~img/gevestor/icon-trust1.png") left 2rem no-repeat;
            background-size: 2.9rem auto;
        }
        .nl__leagel {
            font-size: 13px;
            color: $gray03;
            line-height: 1.5;
            padding-top: 1.5rem;
            a {
                color: $gray04;
            }
        }
        &.sidebar-sticked {
            top: 150px;
        }
    }

    .teaser {
        &.teaser--newsletter {
            margin-top: 70px;
            margin-bottom: 2rem;
            height: calc(100% - 5rem);
            display: block;
            color: $gray01;

            .teaser__img {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                position: absolute;
                top: -60px;
                left: 50%;
                margin-left: -60px;
                border: solid 1px $gray04;
            }
            .teaser__inner {
                padding: 3.5rem 1.5rem 1.5rem;
                text-align: left;
                font-size: 16px;
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                justify-content: center;
                flex-direction: column;

               
                ul {
                    list-style: none;
                    padding: 0;
        
                    li {
                        margin: .5rem 0;
                        padding-left: 1.75rem;
                        position: relative;
        
                        &:before {
                            content: '';
                            display: inline-block;
                            height: 18px;
                            width: 18px;
                            position: absolute;
                            top: 5px;
                            left: 0;
                            background: url(~img/gevestor/icon-check.svg) left center no-repeat;
                            background-size: 100%;
                        }
                    }
                }
            }
            .teaser__title {
                font-size: 1.3rem;
                margin-bottom: 0;
                text-align: center;
            }
            .teaser__info {
                justify-content: center;
                font-size: 14px;

                .teaser__cat {
                    margin-right: unset;
                    padding: .1rem .75rem;
                    margin: .5rem .25rem 0;
                    background-color: $gray03;
                    cursor: auto;

                    &:before {
                        content: none;
                    }

                    .cat__text {
                        color: #fff;
                    }
                }
            }
            .btn {
                margin-top: auto;
                margin-left: auto;
                margin-right: auto;
            }
            .btn-select, .btn-selected {
                padding-left: 2.5rem;

                &::before {
                    content: '';
                    display: inline-block;
                    height: 22px;
                    width: 22px;
                    border: solid 1px $gray03;
                    margin-right: 7px;
                    background-color: rgba(255,255,255,.8);
                    background-size: 100%;
                    position: absolute;
                    top: 9px;
                    left: .5rem;
                }
            }
            .btn-selected {
                display: none;
                color: $gray02;
                background-color: $gray05;
                border-color: $gray05;;
                position: relative;

                &::before {
                    border: solid 1px $gray03;
                    background: rgba(255,255,255,.8) url(~img/gevestor/icon-check.svg) center center no-repeat;
                }
            }
            &:hover {
                text-decoration: none;
            }

            &.newsletter-selected {
                color: $gray03;

                .teaser__title {
                    color: $gray03;
                }
                .btn-primary {
                    display: none;
                }
                .btn-selected {
                    display: block;
                }
            }
        }

    }
    .newsletter--registered {
        .teaser {
            opacity: .5;

            .btn {
                display: none;
            }
        }
    }
}

// Responsive
// --------------------------------------------------
@import "responsive/newsletter-sm";