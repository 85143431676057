html {
	--wp-admin--admin-bar--height: 32px;
	scroll-padding-top: var(--wp-admin--admin-bar--height);
}

#wpadminbar * {
	height: auto;
	width: auto;
	margin: 0;
	padding: 0;
	position: static;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	font-size: 13px;
	font-weight: 400;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	font-style: normal;
	line-height: 2.46153846;
	border-radius: 0;
	box-sizing: content-box;
	transition: none;
	-webkit-font-smoothing: subpixel-antialiased; /* Prevent Safari from switching to standard antialiasing on hover */
	-moz-osx-font-smoothing: auto; /* Prevent Firefox from inheriting from themes that use other values */
}

.rtl #wpadminbar * {
	font-family: Tahoma, sans-serif;
}

html:lang(he-il) .rtl #wpadminbar * {
	font-family: Arial, sans-serif;
}

#wpadminbar .ab-empty-item {
	cursor: default;
}

#wpadminbar .ab-empty-item,
#wpadminbar a.ab-item,
#wpadminbar > #wp-toolbar span.ab-label,
#wpadminbar > #wp-toolbar span.noticon {
	color: #f0f0f1;
}

#wpadminbar #wp-admin-bar-site-name a.ab-item,
#wpadminbar #wp-admin-bar-my-sites a.ab-item {
	white-space: nowrap;
}

#wpadminbar ul li:before,
#wpadminbar ul li:after {
	content: normal;
}

#wpadminbar a,
#wpadminbar a:hover,
#wpadminbar a img,
#wpadminbar a img:hover {
	border: none;
	text-decoration: none;
	background: none;
	box-shadow: none;
}

#wpadminbar a:focus,
#wpadminbar a:active,
#wpadminbar input[type="text"],
#wpadminbar input[type="password"],
#wpadminbar input[type="number"],
#wpadminbar input[type="search"],
#wpadminbar input[type="email"],
#wpadminbar input[type="url"],
#wpadminbar select,
#wpadminbar textarea,
#wpadminbar div {
	box-shadow: none;
}

#wpadminbar a:focus {
	/* Inherits transparent outline only visible in Windows High Contrast mode */
	outline-offset: -1px;
}

#wpadminbar {
	direction: ltr;
	color: #c3c4c7;
	font-size: 13px;
	font-weight: 400;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	line-height: 2.46153846;
	height: 32px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 600px; /* match the min-width of the body in wp-admin/css/common.css */
	z-index: 99999;
	background: #1d2327;
	/* Only visible in Windows High Contrast mode */
	outline: 1px solid transparent;
}

#wpadminbar .ab-sub-wrapper,
#wpadminbar ul,
#wpadminbar ul li {
	background: none;
	clear: none;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	text-indent: 0;
	z-index: 99999;
}

#wpadminbar ul#wp-admin-bar-root-default>li {
	margin-right: 0;
}

#wpadminbar .quicklinks ul {
	text-align: left;
}

#wpadminbar li {
	float: left;
}

#wpadminbar .ab-empty-item {
	outline: none;
}

#wpadminbar .quicklinks a,
#wpadminbar .quicklinks .ab-empty-item,
#wpadminbar .shortlink-input {
	height: 32px;
	display: block;
	padding: 0 10px;
	margin: 0;
}

#wpadminbar .quicklinks > ul > li > a {
	padding: 0 8px 0 7px;
}

#wpadminbar .menupop .ab-sub-wrapper,
#wpadminbar .shortlink-input {
	margin: 0;
	padding: 0;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	background: #2c3338;
	display: none;
	position: absolute;
	float: none;
}

#wpadminbar .ab-top-menu > .menupop > .ab-sub-wrapper {
	min-width: 100%;
}

#wpadminbar .ab-top-secondary .menupop .ab-sub-wrapper {
	right: 0;
	left: auto;
}

#wpadminbar .ab-submenu {
	padding: 6px 0;
}

#wpadminbar .selected .shortlink-input {
	display: block;
}

#wpadminbar .quicklinks .menupop ul li {
	float: none;
}

#wpadminbar .quicklinks .menupop ul li a strong {
	font-weight: 600;
}

#wpadminbar .quicklinks .menupop ul li .ab-item,
#wpadminbar .quicklinks .menupop ul li a strong,
#wpadminbar .quicklinks .menupop.hover ul li .ab-item,
#wpadminbar.nojs .quicklinks .menupop:hover ul li .ab-item,
#wpadminbar .shortlink-input {
	line-height: 2;
	height: 26px;
	white-space: nowrap;
	min-width: 140px;
}

#wpadminbar .shortlink-input {
	width: 200px;
}

#wpadminbar.nojs li:hover > .ab-sub-wrapper,
#wpadminbar li.hover > .ab-sub-wrapper {
	display: block;
	/* Only visible in Windows High Contrast mode */
	outline: 1px solid transparent;
}

#wpadminbar .menupop li:hover > .ab-sub-wrapper,
#wpadminbar .menupop li.hover > .ab-sub-wrapper {
	margin-left: 100%;
	margin-top: -32px;
}

#wpadminbar .ab-top-secondary .menupop li:hover > .ab-sub-wrapper,
#wpadminbar .ab-top-secondary .menupop li.hover > .ab-sub-wrapper {
	margin-left: 0;
	left: inherit;
	right: 100%;
}

#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus,
#wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar .ab-top-menu > li.hover > .ab-item {
	background: #2c3338;
	color: #72aee6;
}

#wpadminbar:not(.mobile) > #wp-toolbar li:hover span.ab-label,
#wpadminbar > #wp-toolbar li.hover span.ab-label,
#wpadminbar:not(.mobile) > #wp-toolbar a:focus span.ab-label {
	color: #72aee6;
}

#wpadminbar > #wp-toolbar > #wp-admin-bar-root-default .ab-icon,
#wpadminbar .ab-icon,
#wpadminbar .ab-item:before,
.wp-admin-bar-arrow {
	position: relative;
	float: left;
	font: normal 20px/1 dashicons;
	speak: never;
	padding: 4px 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: none !important;
	margin-right: 6px;
}

#wpadminbar .ab-icon:before,
#wpadminbar .ab-item:before,
#wpadminbar #adminbarsearch:before {
	color: #a7aaad;
	color: rgba(240, 246, 252, 0.6);
}

#wpadminbar .ab-icon:before,
#wpadminbar .ab-item:before,
#wpadminbar #adminbarsearch:before {
	position: relative;
	transition: color .1s ease-in-out;
}

#wpadminbar .ab-label {
	display: inline-block;
	height: 32px;
}

#wpadminbar .ab-submenu .ab-item {
	color: #c3c4c7;
	color: rgba(240, 246, 252, 0.7);
}

#wpadminbar .quicklinks .menupop ul li a,
#wpadminbar .quicklinks .menupop ul li a strong,
#wpadminbar .quicklinks .menupop.hover ul li a,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a {
	color: #c3c4c7;
	color: rgba(240, 246, 252, 0.7);
}

#wpadminbar .quicklinks .menupop ul li a:hover,
#wpadminbar .quicklinks .menupop ul li a:focus,
#wpadminbar .quicklinks .menupop ul li a:hover strong,
#wpadminbar .quicklinks .menupop ul li a:focus strong,
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a,
#wpadminbar .quicklinks .menupop.hover ul li a:hover,
#wpadminbar .quicklinks .menupop.hover ul li a:focus,
#wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:hover,
#wpadminbar .quicklinks .menupop.hover ul li div[tabindex]:focus,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a:focus,
#wpadminbar li:hover .ab-icon:before,
#wpadminbar li:hover .ab-item:before,
#wpadminbar li a:focus .ab-icon:before,
#wpadminbar li .ab-item:focus:before,
#wpadminbar li .ab-item:focus .ab-icon:before,
#wpadminbar li.hover .ab-icon:before,
#wpadminbar li.hover .ab-item:before,
#wpadminbar li:hover #adminbarsearch:before,
#wpadminbar li #adminbarsearch.adminbar-focused:before {
	color: #72aee6;
}

#wpadminbar.mobile .quicklinks .ab-icon:before,
#wpadminbar.mobile .quicklinks .ab-item:before {
	color: #c3c4c7;
}

#wpadminbar.mobile .quicklinks .hover .ab-icon:before,
#wpadminbar.mobile .quicklinks .hover .ab-item:before {
	color: #72aee6;
}

#wpadminbar .menupop .menupop > .ab-item .wp-admin-bar-arrow:before,
#wpadminbar .ab-top-secondary .menupop .menupop > .ab-item:before {
	position: absolute;
	font: normal 17px/1 dashicons;
	speak: never;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#wpadminbar .menupop .menupop > .ab-item {
	display: block;
	padding-right: 2em;
}

#wpadminbar .menupop .menupop > .ab-item .wp-admin-bar-arrow:before {
	top: 1px;
	right: 10px;
	padding: 4px 0;
	content: "\f139";
	color: inherit;
}

#wpadminbar .ab-top-secondary .menupop .menupop > .ab-item {
	padding-left: 2em;
	padding-right: 1em;
}

#wpadminbar .ab-top-secondary .menupop .menupop > .ab-item .wp-admin-bar-arrow:before {
	top: 1px;
	left: 6px;
	content: "\f141";
}

#wpadminbar .quicklinks .menupop ul.ab-sub-secondary {
	display: block;
	position: relative;
	right: auto;
	margin: 0;
	box-shadow: none;
}

#wpadminbar .quicklinks .menupop ul.ab-sub-secondary,
#wpadminbar .quicklinks .menupop ul.ab-sub-secondary .ab-submenu {
	background: #3c434a;
}

#wpadminbar .quicklinks .menupop .ab-sub-secondary > li > a:hover,
#wpadminbar .quicklinks .menupop .ab-sub-secondary > li .ab-item:focus a {
	color: #72aee6;
}

#wpadminbar .quicklinks a span#ab-updates {
	background: #f0f0f1;
	color: #2c3338;
	display: inline;
	padding: 2px 5px;
	font-size: 10px;
	font-weight: 600;
	border-radius: 10px;
}

#wpadminbar .quicklinks a:hover span#ab-updates {
	background: #fff;
	color: #000;
}

#wpadminbar .ab-top-secondary {
	float: right;
}

#wpadminbar ul li:last-child,
#wpadminbar ul li:last-child .ab-item {
	box-shadow: none;
}

/**
 * Recovery Mode
 */
#wpadminbar #wp-admin-bar-recovery-mode {
	color: #fff;
	background-color: #d63638;
}

#wpadminbar .ab-top-menu > #wp-admin-bar-recovery-mode.hover >.ab-item,
#wpadminbar.nojq .quicklinks .ab-top-menu > #wp-admin-bar-recovery-mode > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > #wp-admin-bar-recovery-mode:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > #wp-admin-bar-recovery-mode > .ab-item:focus {
	color: #fff;
	background-color: #d63638;
}

/**
 * My Account
 */
#wp-admin-bar-my-account > ul {
	min-width: 198px;
}

#wp-admin-bar-my-account:not(.with-avatar) > .ab-item {
	display: inline-block;
}

#wp-admin-bar-my-account > .ab-item:before {
	content: "\f110";
	top: 2px;
	float: right;
	margin-left: 6px;
	margin-right: 0;
}

#wp-admin-bar-my-account.with-avatar > .ab-item:before {
	display: none;
	content: none;
}

#wp-admin-bar-my-account.with-avatar > ul {
	min-width: 270px;
}

#wpadminbar #wp-admin-bar-user-actions > li {
	margin-left: 16px;
	margin-right: 16px;
}

#wpadminbar #wp-admin-bar-user-actions.ab-submenu {
	padding: 6px 0 12px;
}

#wpadminbar #wp-admin-bar-my-account.with-avatar #wp-admin-bar-user-actions > li {
	margin-left: 88px;
}

#wpadminbar #wp-admin-bar-user-info {
	margin-top: 6px;
	margin-bottom: 15px;
	height: auto;
	background: none;
}

#wp-admin-bar-user-info .avatar {
	position: absolute;
	left: -72px;
	top: 4px;
	width: 64px;
	height: 64px;
}

#wpadminbar #wp-admin-bar-user-info a {
	background: none;
	height: auto;
}

#wpadminbar #wp-admin-bar-user-info span {
	background: none;
	padding: 0;
	height: 18px;
}

#wpadminbar #wp-admin-bar-user-info .display-name,
#wpadminbar #wp-admin-bar-user-info .username {
	display: block;
}

#wpadminbar #wp-admin-bar-user-info .username {
	color: #a7aaad;
	font-size: 11px;
}

#wpadminbar #wp-admin-bar-my-account.with-avatar > .ab-empty-item img,
#wpadminbar #wp-admin-bar-my-account.with-avatar > a img {
	width: auto;
	height: 16px;
	padding: 0;
	border: 1px solid #8c8f94;
	background: #f0f0f1;
	line-height: 1.84615384;
	vertical-align: middle;
	margin: -4px 0 0 6px;
	float: none;
	display: inline;
}

/**
 * WP Logo
 */
#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon {
	width: 15px;
	height: 20px;
	margin-right: 0;
	padding: 6px 0 5px;
}

#wpadminbar #wp-admin-bar-wp-logo > .ab-item {
	padding: 0 7px;
}

#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon:before {
	content: "\f120";
	top: 2px;
}

/*
 * My Sites & Site Title
 */
#wpadminbar .quicklinks li .blavatar {
	display: inline-block;
	vertical-align: middle;
	font: normal 16px/1 dashicons !important;
	speak: never;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #f0f0f1;
}

#wpadminbar .quicklinks li a:hover .blavatar,
#wpadminbar .quicklinks li a:focus .blavatar,
#wpadminbar .quicklinks .ab-sub-wrapper .menupop.hover > a .blavatar {
	color: #72aee6;
}

#wpadminbar .quicklinks li img.blavatar,
#wpadminbar .quicklinks li div.blavatar:before {
	height: 16px;
	width: 16px;
	margin: 0 8px 2px -2px;
}

#wpadminbar .quicklinks li div.blavatar:before {
	content: "\f120";
	display: inline-block;
}

#wpadminbar #wp-admin-bar-appearance {
	margin-top: -12px;
}

#wpadminbar #wp-admin-bar-my-sites > .ab-item:before,
#wpadminbar #wp-admin-bar-site-name > .ab-item:before {
	content: "\f541";
	top: 2px;
}

#wpadminbar #wp-admin-bar-site-editor > .ab-item:before {
	content: "\f100";
	top: 2px;
}

#wpadminbar #wp-admin-bar-customize > .ab-item:before {
	content: "\f540";
	top: 2px;
}

#wpadminbar #wp-admin-bar-edit > .ab-item:before {
	content: "\f464";
	top: 2px;
}

#wpadminbar #wp-admin-bar-site-name > .ab-item:before {
	content: "\f226";
}

.wp-admin #wpadminbar #wp-admin-bar-site-name > .ab-item:before {
	content: "\f102";
}



/**
 * Comments
 */
#wpadminbar #wp-admin-bar-comments .ab-icon {
	margin-right: 6px;
}

#wpadminbar #wp-admin-bar-comments .ab-icon:before {
	content: "\f101";
	top: 3px;
}

#wpadminbar #wp-admin-bar-comments .count-0 {
	opacity: .5;
}

/**
 * New Content
 */
#wpadminbar #wp-admin-bar-new-content .ab-icon:before {
	content: "\f132";
	top: 4px;
}

/**
 * Updates
 */
#wpadminbar #wp-admin-bar-updates .ab-icon:before {
	content: "\f463";
	top: 2px;
}

#wpadminbar #wp-admin-bar-updates.spin .ab-icon:before {
	display: inline-block;
	animation: rotation 2s infinite linear;
}

@media (prefers-reduced-motion: reduce) {
	#wpadminbar #wp-admin-bar-updates.spin .ab-icon:before {
		animation: none;
	}
}

/**
 * Search
 */

#wpadminbar #wp-admin-bar-search .ab-item {
	padding: 0;
	background: transparent;
}

#wpadminbar #adminbarsearch {
	position: relative;
	height: 32px;
	padding: 0 2px;
	z-index: 1;
}

#wpadminbar #adminbarsearch:before {
	position: absolute;
	top: 6px;
	left: 5px;
	z-index: 20;
	font: normal 20px/1 dashicons !important;
	content: "\f179";
	speak: never;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* The admin bar search field needs to reset many styles that might be inherited from the active Theme CSS. See ticket #40313. */
#wpadminbar > #wp-toolbar > #wp-admin-bar-top-secondary > #wp-admin-bar-search #adminbarsearch input.adminbar-input {
	display: inline-block;
	float: none;
	position: relative;
	z-index: 30;
	font-size: 13px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	line-height: 1.84615384;
	text-indent: 0;
	height: 24px;
	width: 24px;
	max-width: none;
	padding: 0 3px 0 24px;
	margin: 0;
	color: #c3c4c7;
	background-color: rgba(255, 255, 255, 0);
	border: none;
	outline: none;
	cursor: pointer;
	box-shadow: none;
	box-sizing: border-box;
	transition-duration: 400ms;
	transition-property: width, background;
	transition-timing-function: ease;
}

#wpadminbar > #wp-toolbar > #wp-admin-bar-top-secondary > #wp-admin-bar-search #adminbarsearch input.adminbar-input:focus {
	z-index: 10;
	color: #000;
	width: 200px;
	background-color: rgba(255, 255, 255, 0.9);
	cursor: text;
	border: 0;
}

#wpadminbar #adminbarsearch .adminbar-button {
	display: none;
}

/**
 * Customize support classes
 */
.no-customize-support .hide-if-no-customize,
.customize-support .hide-if-customize,
.no-customize-support #wpadminbar .hide-if-no-customize,
.no-customize-support.wp-core-ui .hide-if-no-customize,
.no-customize-support .wp-core-ui .hide-if-no-customize,
.customize-support #wpadminbar .hide-if-customize,
.customize-support.wp-core-ui .hide-if-customize,
.customize-support .wp-core-ui .hide-if-customize {
	display: none;
}

/* Skip link */
#wpadminbar .screen-reader-text,
#wpadminbar .screen-reader-text span {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

#wpadminbar .screen-reader-shortcut {
	position: absolute;
	top: -1000em;
	left: 6px;
	height: auto;
	width: auto;
	display: block;
	font-size: 14px;
	font-weight: 600;
	padding: 15px 23px 14px;
	background: #f0f0f1;
	color: #2271b1;
	z-index: 100000;
	line-height: normal;
	text-decoration: none;
}

#wpadminbar .screen-reader-shortcut:focus {
	top: 7px;
	background: #f0f0f1;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 782px) {
	html {
		--wp-admin--admin-bar--height: 46px;
	}

	/* Toolbar Touchification*/
	html #wpadminbar {
		height: 46px;
		min-width: 240px; /* match the min-width of the body in wp-admin/css/common.css */
	}

	#wpadminbar * {
		font-size: 14px;
		font-weight: 400;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		line-height: 2.28571428;
	}

	#wpadminbar .quicklinks > ul > li > a,
	#wpadminbar .quicklinks .ab-empty-item {
		padding: 0;
		height: 46px;
		line-height: 3.28571428;
		width: auto;
	}

	#wpadminbar .ab-icon {
		font: 40px/1 dashicons !important;
		margin: 0;
		padding: 0;
		width: 52px;
		height: 46px;
		text-align: center;
	}

	#wpadminbar .ab-icon:before {
		text-align: center;
	}

	#wpadminbar .ab-submenu {
		padding: 0;
	}

	#wpadminbar #wp-admin-bar-site-name a.ab-item,
	#wpadminbar #wp-admin-bar-my-sites a.ab-item,
	#wpadminbar #wp-admin-bar-my-account a.ab-item {
		text-overflow: clip;
	}

	#wpadminbar .quicklinks .menupop ul li .ab-item,
	#wpadminbar .quicklinks .menupop ul li a strong,
	#wpadminbar .quicklinks .menupop.hover ul li .ab-item,
	#wpadminbar.nojs .quicklinks .menupop:hover ul li .ab-item,
	#wpadminbar .shortlink-input {
	    line-height: 1.6;
	}

	#wpadminbar .ab-label {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important;
	}

	#wpadminbar .menupop li:hover > .ab-sub-wrapper,
	#wpadminbar .menupop li.hover > .ab-sub-wrapper {
		margin-top: -46px;
	}

	#wpadminbar .ab-top-menu .menupop .ab-sub-wrapper .menupop > .ab-item {
		padding-right: 30px;
	}

	#wpadminbar .menupop .menupop > .ab-item:before {
		top: 10px;
		right: 6px;
	}

	#wpadminbar .ab-top-menu > .menupop > .ab-sub-wrapper .ab-item {
		font-size: 16px;
		padding: 8px 16px;
	}

	#wpadminbar .ab-top-menu > .menupop > .ab-sub-wrapper a:empty {
		display: none;
	}

	/* WP logo */
	#wpadminbar #wp-admin-bar-wp-logo > .ab-item {
		padding: 0;
	}

	#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon {
		padding: 0;
		width: 52px;
		height: 46px;
		text-align: center;
		vertical-align: top;
	}

	#wpadminbar #wp-admin-bar-wp-logo > .ab-item .ab-icon:before {
		font: 28px/1 dashicons !important;
		top: -3px;
	}

	#wpadminbar .ab-icon,
	#wpadminbar .ab-item:before {
		padding: 0;
	}

	/* My Sites and "Site Title" menu */
	#wpadminbar #wp-admin-bar-my-sites > .ab-item,
	#wpadminbar #wp-admin-bar-site-name > .ab-item,
	#wpadminbar #wp-admin-bar-site-editor > .ab-item,
	#wpadminbar #wp-admin-bar-customize > .ab-item,
	#wpadminbar #wp-admin-bar-edit > .ab-item,
	#wpadminbar #wp-admin-bar-my-account > .ab-item {
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		width: 52px;
		padding: 0;
		color: #a7aaad; /* @todo not needed? this text is hidden */
		position: relative;
	}

	#wpadminbar > #wp-toolbar > #wp-admin-bar-root-default .ab-icon,
	#wpadminbar .ab-icon,
	#wpadminbar .ab-item:before {
		padding: 0;
		margin-right: 0;
	}

	#wpadminbar #wp-admin-bar-edit > .ab-item:before,
	#wpadminbar #wp-admin-bar-my-sites > .ab-item:before,
	#wpadminbar #wp-admin-bar-site-name > .ab-item:before,
	#wpadminbar #wp-admin-bar-site-editor > .ab-item:before,
	#wpadminbar #wp-admin-bar-customize > .ab-item:before,
	#wpadminbar #wp-admin-bar-my-account > .ab-item:before {
		display: block;
		text-indent: 0;
		font: normal 32px/1 dashicons;
		speak: never;
		top: 7px;
		width: 52px;
		text-align: center;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#wpadminbar #wp-admin-bar-appearance {
		margin-top: 0;
	}

	#wpadminbar .quicklinks li .blavatar:before {
		display: none;
	}

	/* Search */
	#wpadminbar #wp-admin-bar-search {
		display: none;
	}

	/* New Content */
	#wpadminbar #wp-admin-bar-new-content .ab-icon:before {
		top: 0;
		line-height: 1.26;
		height: 46px !important;
		text-align: center;
		width: 52px;
		display: block;
	}

	/* Updates */
	#wpadminbar #wp-admin-bar-updates {
		text-align: center;
	}

	#wpadminbar #wp-admin-bar-updates .ab-icon:before {
		top: 3px;
	}

	/* Comments */
	#wpadminbar #wp-admin-bar-comments .ab-icon {
		margin: 0;
	}

	#wpadminbar #wp-admin-bar-comments .ab-icon:before {
		display: block;
		font-size: 34px;
		height: 46px;
		line-height: 1.38235294;
		top: 0;
	}

	/* My Account */
	#wpadminbar #wp-admin-bar-my-account > a {
		position: relative;
		white-space: nowrap;
		text-indent: 150%; /* More than 100% indention is needed since this element has padding */
		width: 28px;
		padding: 0 10px;
		overflow: hidden; /* Prevent link text from forcing horizontal scrolling on mobile */
	}

	#wpadminbar .quicklinks li#wp-admin-bar-my-account.with-avatar > a img {
		position: absolute;
		top: 13px;
		right: 10px;
		width: 26px;
		height: 26px;
	}

	#wpadminbar #wp-admin-bar-user-actions.ab-submenu {
		padding: 0;
	}

	#wpadminbar #wp-admin-bar-user-actions.ab-submenu img.avatar {
		display: none;
	}

	#wpadminbar #wp-admin-bar-my-account.with-avatar #wp-admin-bar-user-actions > li {
		margin: 0;
	}

	#wpadminbar #wp-admin-bar-user-info .display-name {
		height: auto;
		font-size: 16px;
		line-height: 1.5;
		color: #f0f0f1;
	}

	#wpadminbar #wp-admin-bar-user-info a {
		padding-top: 4px;
	}

	#wpadminbar #wp-admin-bar-user-info .username {
		line-height: 0.8 !important;
		margin-bottom: -2px;
	}

	/* Show only default top level items */
	#wp-toolbar > ul > li {
		display: none;
	}

	#wpadminbar li#wp-admin-bar-menu-toggle,
	#wpadminbar li#wp-admin-bar-wp-logo,
	#wpadminbar li#wp-admin-bar-my-sites,
	#wpadminbar li#wp-admin-bar-updates,
	#wpadminbar li#wp-admin-bar-site-name,
	#wpadminbar li#wp-admin-bar-site-editor,
	#wpadminbar li#wp-admin-bar-customize,
	#wpadminbar li#wp-admin-bar-new-content,
	#wpadminbar li#wp-admin-bar-edit,
	#wpadminbar li#wp-admin-bar-comments,
	#wpadminbar li#wp-admin-bar-my-account {
		display: block;
	}

	/* Allow dropdown list items to appear normally */
	#wpadminbar li:hover ul li,
	#wpadminbar li.hover ul li,
	#wpadminbar li:hover ul li:hover ul li {
		display: list-item;
	}

	/* Override default min-width so dropdown lists aren't stretched
		to 100% viewport width at responsive sizes. */
	#wpadminbar .ab-top-menu > .menupop > .ab-sub-wrapper {
		min-width: fit-content;
	}

	#wpadminbar ul#wp-admin-bar-root-default > li {
		margin-right: 0;
	}

	/* Experimental fix for touch toolbar dropdown positioning */
	#wpadminbar .ab-top-menu,
	#wpadminbar .ab-top-secondary,
	#wpadminbar #wp-admin-bar-wp-logo,
	#wpadminbar #wp-admin-bar-my-sites,
	#wpadminbar #wp-admin-bar-site-name,
	#wpadminbar #wp-admin-bar-updates,
	#wpadminbar #wp-admin-bar-comments,
	#wpadminbar #wp-admin-bar-new-content,
	#wpadminbar #wp-admin-bar-edit,
	#wpadminbar #wp-admin-bar-my-account {
		position: static;
	}

	.network-admin #wpadminbar ul#wp-admin-bar-top-secondary > li#wp-admin-bar-my-account {
		margin-right: 0;
	}

	/* Realign arrows on taller responsive submenus */

	#wpadminbar .ab-top-secondary .menupop .menupop > .ab-item:before {
		top: 10px;
		left: 0;
	}
}

/* Smartphone */
@media screen and (max-width: 600px) {
	#wpadminbar {
		position: absolute;
	}

	#wp-responsive-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 400;
	}

	#wpadminbar .ab-top-menu > .menupop > .ab-sub-wrapper {
		width: 100%;
		left: 0;
	}

	#wpadminbar .menupop .menupop > .ab-item:before {
		display: none;
	}

	#wpadminbar #wp-admin-bar-wp-logo.menupop .ab-sub-wrapper {
		margin-left: 0;
	}

	#wpadminbar .ab-top-menu > .menupop li > .ab-sub-wrapper {
		margin: 0;
		width: 100%;
		top: auto;
		left: auto;
		position: relative;
	}

	#wpadminbar .ab-top-menu > .menupop li > .ab-sub-wrapper .ab-item {
		font-size: 16px;
		padding: 6px 15px 19px 30px;
	}

	#wpadminbar li:hover ul li ul li {
		display: list-item;
	}

	#wpadminbar li#wp-admin-bar-wp-logo,
	#wpadminbar li#wp-admin-bar-updates {
		display: none;
	}

	/* Make submenus full-width at this size */

	#wpadminbar .ab-top-menu > .menupop li > .ab-sub-wrapper {
		position: static;
		box-shadow: none;
	}
}

/* Very narrow screens */
@media screen and (max-width: 400px) {
	#wpadminbar li#wp-admin-bar-comments {
		display: none;
	}
}
