//
// Bot
// --------------------------------------------------
.contact-bot {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 20;
    height: 60px;
    width: 60px;

    &.show {
        height: auto;
        width: auto;
    }
    .bot__toggle {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-color: $brand01;
        background-image: url(~img/gevestor/icon-chat.png);
        background-position: center center;
        background-size: auto 60%;
        background-repeat: no-repeat;
        transition: $transition-base;
        box-shadow: $box-shadow;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1010;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
            transition: $transition-base;
        }
        &:active, &:focus {
            transform: scale(.8) !important;
            transition: $transition-base;
        }
    }
    .bot__window {
        border: solid 1px $gray04;
        box-shadow: $box-shadow;
        background: #fff;
        height: auto;
        max-height: 0;
        width: 375px;
        position: relative;
        z-index: 1005;
        transition: $transition-base;
        transform: scale(0);
        transform-origin: bottom right;
        margin-right: -5px;
        margin-bottom: -5px;
        font-size: 15px;

        &.show {
            transform: scale(1);
            z-index: 1015;
            max-height: 800px;
        }

        .bot__header {
            background: $gray02;
            padding: 3.5rem 1.5rem 1rem;
            color: #fff;

            .close-icon {
                right: 1rem;
                top: 1rem;

                .vertical, .horizontal {
                    background-color: #fff;
                }
            }

            .bot__greetings {
                @include font-title-h2;
                color: #fff;
                display: flex;
                align-items: center;
                margin-bottom: .5rem;

                .emoji {
                    max-width: 32px;
                    height: auto;
                    margin-top: -12px;
                    margin-left: 12px;
                }
            }
        }
        .nav-tabs {
            background: $gray03;
            margin-top: -1rem;
            border-bottom: none;

            .nav-item {
                .nav-link {
                    padding: 0.3rem 1.25rem;
                    border-top: solid 1px #fff;
                    border-right: solid 1px #fff;
                    border-left: none;

                    .navicon {
                        width: 32px;
                        height: auto;

                        &.active {
                            display: none;
                        }
                    }

                    &.active {
                        .navicon {
                            &.default {
                                display: none;
                            }
    
                            &.active {
                                display: block;
                            }
                        } 
                    }
                }
            }
            .navicon {
                width: 40px;
                height: auto;
            }
        }
        .tab-pane {
            padding: 1rem 0;
            min-height: 350px;
        }
        .bot__content {
            padding: 1.5rem 1.5rem 2rem;
            a {
                color: $gray02;
            }
            .big {
                @include font-title-h3;
            }
            .bot__phone {
                margin-bottom: 1rem;
                .big {
                    @include font-title-h2;
                } 
            }
            a.link {
                color: $gray01;
            }
        }
        .bot__cta {
            margin-top: 1.25rem;
            margin-bottom: .5rem;
            display: flex;
            align-items: center;

            .btn--secondary {
                margin-left: auto;
            }
        }
        .btn-send {
            height: 34px;
            float: right;
            margin-top: -75px;
            margin-right: 10px;
        }
        .bot__options {
            display: flex;
            justify-content: space-between;
            margin: .5rem 0 1rem;

            .bot__option {
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                color: $brand01;
                text-align: center;
                cursor: pointer;

                .option__icon {
                    max-width: 45px;
                    margin: 0 auto .5rem;
                }

                &:hover {
                    color: $gray02;
                }
            }
        }
    }
}
// Responsive
// --------------------------------------------------
@media (max-width: 767px) {
    .contact-bot {
        bottom: 16px;
        right: 16px;
    }
}