//
// Type MD
// --------------------------------------------------
@media (max-width: 991px) {
    h1 {
    font-size: $font-size-h1-md;
    }
    h2 {
        font-size: $font-size-h2-md;
    }
    h3 {
        font-size: $font-size-h3-md;
    }
    h4 {
    @include font-title-h4; 
    }
}
// Responsive
// --------------------------------------------------
