//
// Messages
// --------------------------------------------------
.message {
	padding: 1rem 2rem;
    background: $gray02;
    color: #fff;
    letter-spacing: 1px;
    margin: 1rem 0 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: $transition-base;

    &.message--brand {
        background: $brand01;
    }
    &.message--alert {
        background: $brand02;
    }
    .message__arrow {
        display: block;
        height: 15px;
        width: 15px;
        border-bottom: solid 3px #fff;
        border-right: solid 3px #fff;
        transform: rotate(-45deg);
        margin-left: auto;
        z-index: 10;
        transition: $transition-base;
    }

    &:hover {
        box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.2); 
        padding: 1.2rem 1.5rem 1.2rem 2.5rem;
        color: #fff;
        text-decoration: none;
    }
}
.messages--home {
    background: $gray06;
    padding: 2rem 0 0;
    
    .message {
        margin: 0;
    }
}

// Responsive
// --------------------------------------------------
@import "responsive/message-sm";