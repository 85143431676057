//
// PPM Mixins
// --------------------------------------------------

// Lists
// --------------------------------------------------
@mixin reset-list {
  	margin: 0;
  	padding: 0;
  	list-style: none;
}



// Typography
// --------------------------------------------------
// Type
// --------------------------------------------------
@mixin font-base {
	font-family: $font-family;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: $font-weight-base;
	color: $gray01;
}
@mixin font-title {
    font-family: $font-family-display;
    font-weight: $font-weight-display;
	color: $gray02;
	margin-top: 0;
	margin-bottom: 0;
}
@mixin font-title-h1 {
    @include font-title;
    font-size: $font-size-h1;
    line-height: $line-height-h1;
}
@mixin font-title-h2 {
    @include font-title;
    font-size: $font-size-h2;
    color: $gray02;
    line-height: 1.6;
}
@mixin font-title-h3 {
    @include font-title;
    font-size: $font-size-h3;
    line-height: 1.5;
}
@mixin font-title-h4 {
    @include font-title;
    font-size: $font-size-h4;
}
@mixin font-btn {
	font-family: $font-family;
    font-weight: $font-weight-base;
	font-size: 16px;
	line-height: normal;
	color: #fff;
	text-transform: uppercase;
}
@mixin font-nav {
    text-transform: uppercase;
    font-weight: $font-weight-base;
    font-size: $font-size-nav;
    color: $gray01;
}