//
// Base MD
// --------------------------------------------------

/* SECTIONS */
@media (max-width: 767px) {
    .block {
        padding: 2rem 0 3rem;

        .block__info {
            .block__title {
                font-size: $font-size-h1-sm;
                margin-bottom: 1.5rem;
            }
    
        }
        .block__btn {
            margin-bottom: 2rem;
        }
        .block__filter {
            margin: 0;
        }
    }
}
// Responsive
// --------------------------------------------------
