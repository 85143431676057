//
// Carousel SM
// --------------------------------------------------
@media (max-width: 767px) {
    .section--carousel {
        .container {
            padding-left: 0;
            padding-right: 0;

            .row {
                margin-right: 0;
                margin-left: 0;;
            }
            
            .owl-carousel {
                .owl-item {
                    opacity: .75;

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

