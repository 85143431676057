//
// Products SM
// --------------------------------------------------
@media (max-width: 767px) {
    .nav-pills {    
        .nav-link {
            padding: 1rem 1.25rem;
            font-size: 15px;

            &.active {
                background: $gray06;
                color: $gray02;
            }

            .link__icon {
                display: block;
                width: 30px;
                height: auto;
                margin: 0 auto .3rem;  
            }
        }
    }
    .tab-content {
        padding: 2rem 1.5rem 3rem;
    }
    .filter__second {
        .checkbox {
            display: none;
        } 
        .dropdown {
            display: block;
            width: 100%;

            .btn {
                background: white;
                width: 100%;
                color: $gray01;
                border-radius: 0;
                text-transform: none;
                text-align: left;
                padding: 1rem 1.5rem .8rem;
                font-weight: $font-weight-base;
    
                &:after, &:before {
                    transition-duration: 250ms;
                    transition-property: all;
                    transition-timing-function: ease;
                    position: absolute;
                    top: 50%;
                    width: 11px;
                    height: 2px;
                    background-color: $gray02;
                    transform-origin: bottom;
                    content: "";
                    border: none;
                }
                &:after {
                    right: 15px;
                    transform: translateY(-50%) translateX(-1px) rotate(-45deg);
                }
                &:before {
                    right: 26px;
                    transform: translateY(-50%) translateX(1px) rotate(45deg);
                }
            }
            &.show {
                .btn {
                    &:after {
                        right: 17px;
                        transform: translateY(-50%) translateX(1px) rotate(45deg);
                    }
                    &:before {
                        right: 21px;
                        transform: translateY(-50%) translateX(-1px) rotate(-45deg);
                    }
                }
            }
            .dropdown-menu {
                position: relative !important;
                width: 100%;
                margin-top: -49px;
                margin-bottom: 2rem;
                padding: 1rem 1rem 1rem 0;
                border-color: $gray04;
            }
            
        }
    }

    .products {
        .teaser {
            &.teaser--product {
    
                .teaser__img {
                    height: 240px;
    
                    img {
                        height: 220px;
                    }
                }
            }
        }
    }

    .productdetail {
        .product__subline {
            font-size: 17px;
            margin-top: .5rem;
        }
        .product__tag {
            font-size: 14px;
            padding: .3rem .75rem;
            position: absolute;
            top: -15px;
            left: 15px;
        }
        .product__intro {
            background: #fff;
            padding: 0;
            margin: 1.5rem 0 2rem;
    
            .product__image {
                margin-bottom: 2rem;
                img {
                    max-width: 240px;
                }
            }
    
            
        }
        .product__buy {
            border-top: none;
            background: $gray06;
            width: calc(100% + 30px);
            margin-left: -15px;
            padding: 1.5rem 15px;

            &.buy--sticky {
                position: fixed;
                top: unset;
                bottom: 0;
                left: 0;
                width: 100%;
                margin: 0;
                align-items: center;
                justify-content: space-between;

                .btn--cta {
                    width: auto;
                }
            }
        }
        .product__subtitle {
            font-size: 1.5rem;
        }
        .product__bonuses {
            padding: 2rem 0;
            width: calc(100% + 30px);
            margin-left: -15px;

            .owl-dots {
                width: calc(100% - 30px);
                margin-left: 15px;
            }

            .item {
                height: 100%;
    
                .bonus__item {
                    .bonus__image {
                        height: 240px;
                   
                        img {
                            max-width: 180px;
                        }
                    }
                }
            }
        }
        .product__benefits {
            margin: 2rem 0;
        }
        .product__testis {
            margin: 2rem 0;

            .testis__quote {
                margin-bottom: 2rem;
            }
            .testi__name {
                font-size: 14px;
                color: $gray02;
                margin-top: .5rem;
            }
        }
        .product__author { 
            padding: 2rem 15px;
            width: calc(100% + 30px);
            margin-left: -15px;
    
            .author__img {
                margin: 0rem auto 2rem;
                max-width: 240px;
            }
        }
        .product__trust {
            width: calc(100% + 30px);
            margin-left: -15px;

            .trust__elements {
                .trust__element{
                    color: #fff;
                    text-align: center;
                    padding: 1.25rem 0;
                }
            }
        }
        .product__form {
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-bottom: -5rem;
        }
    }
    .product__trust {
        padding: .25rem 0;
        flex-direction: column;
        justify-content: center;
    
        .trust__item {
            padding: .5rem 0;
            text-align: center;
            justify-content: center;

            span {
                font-size: 11px;
            }
        }
        &.trust--cat {
            margin-bottom: 0;
        }
    }
    .product-nav {
        display: block;
        margin-bottom: 2rem;
        border: none;
        
        .nav__toggle {
            background: $gray06;
            border-bottom: solid 1px $gray04;
            padding: .75rem 1rem;
            display: flex;
            align-items: center;
            transition: $transition-base;

            &::after {
                content: "";
                display: inline-block;
                height: 20px;
                width: 20px;
                transform: rotate(-90deg);
                background: url("~img/arrow-right-gray.png");
                background-repeat: no-repeat;
                background-size: 90%;
                background-position: right center;
                margin-left: auto;
                transition: $transition-base;
            }

            &.collapsed {
                &::after {
                    transform: rotate(90deg);
                    transition: $transition-base;
                }
            }
        }
        .nav__dropdown {
            transition: $transition-base;

            &.show {
                transition: $transition-base;
            }
        }
        .nav-link {
            display: block;
            color: $gray02;
            border-bottom: solid 2px transparent;
            background: $gray06;
    
            &:hover {
                color: $brand01;
            }
    
            &.active {
                border-bottom: solid 2px $brand01;
            }
        }
    }
}
// Responsive
// --------------------------------------------------