//
// Base MD
// --------------------------------------------------

/* SECTIONS */
@media (max-width: 991px) {
    .block {
        padding: 3rem 0 4rem;


        &.teaser--right {
            .block__info {
                padding-left: 0;
            }
        }
        &.teaser--left {
            .block__info {
                padding-right: 0;
            }
        }

        .block__info {
            .block__title {
                font-size: $font-size-h1-md;
                
                .title__link {
                    .link__arrow {
                        height: 16px;
                        width: 16px;
                        margin-top: -2px;
                    }
                }
            }
    
        }
        .block__btn {
            margin-bottom: 3rem;
        }
        .block__filter {
            margin: 2rem 0 1rem;

            .filter__item {
                padding: .3rem .75rem;
                font-size: 14px;
                margin-right: .5rem;
            }
        }
    }
}
// Responsive
// --------------------------------------------------
