//
// Sitemap
// --------------------------------------------------
.sitemap {
   list-style: none;
   padding-left: 0;
   margin-bottom: 5rem;
   font-size: 14px;
   line-height: 1.5;

   >li {
        flex: 0 0 25%;
        max-width: 25%;

        >a {
            color: $gray01;
            font-weight: $font-weight-bold;
            display: block;
            border-bottom: solid 1px #ccc;
            padding: .5rem 0;
        }
       ul {
           list-style: none;
           padding-left: 0;

           li {
                a {
                    color: $gray01;
                    display: block;
                    border-bottom: solid 1px #ccc;
                    padding: .5rem 0;
                }
            }
            
            &.sitemap--level3 {
               li {
                   a {
                       color: $gray02;
                       padding-left: 10px;
                   }
               }
           }
       }
   }
}
// Responsive
// --------------------------------------------------