//
// Customize Bootstrap
// --------------------------------------------------

// Fonts
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('~fonts/gevestor/open-sans-v20-latin-regular.woff2') format('woff2') /* Super Modern Browsers */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('~fonts/gevestor/open-sans-v20-latin-600.woff2') format('woff2') /* Super Modern Browsers */
}

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('~fonts/gevestor/merriweather-v25-latin-300.woff2') format('woff2') /* Super Modern Browsers */
}

$font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial, sans-serif;
$font-weight-normal:          400;
$font-weight-bold:            700;

// Border radius
$enable-rounded : false;

// Grid
$container-max-widths: (
  	sm: 100%,
  	md: 720px,
  	lg: 100%,
  	xl: 1280px
);