//
// Products
// --------------------------------------------------
.nav-pills {
    margin-top: 2rem;

    .nav-link {
        border: none;
        color: $gray02;
        padding: 1rem 2.5rem;
        font-family: $font-family-display;

        &.active {
            background: $gray06;
            color: $gray02;
        }

        .link__icon {
            display: block;
            width: 34px;
            height: auto;
            margin: 0 auto .3rem;
        }
    }
}
.tab-content {
    background: $gray06;
    padding: 2rem 3rem 3rem;
}
.filter__second {
    display: flex;
    margin-bottom: 2.5rem;

    .dropdown {
        display: none;
    }
    .checkbox {
        font-family: $font-family-display;
        color: $gray02;
        margin: 0 2rem 0 0;
        label {
            color: $gray02;
        }
        .helper {
            top: 5px;
        }
    }
}
.shop {
    .block .block__info .block__title .title__link {
        justify-content: start;
        margin-bottom: 3rem;
    }
}
.products {
    .teaser {
        &.teaser--product {
            margin-bottom: 3.5rem;
            height: calc(100% - 3.5rem);
            transition: $transition-base;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-bottom: 1.5rem;

            .teaser__tag {
                background: $gray02;
                color: #fff;
                text-transform: uppercase;
                font-size: $font-size-small;
                position: absolute;
                padding: .5rem 1.5rem .3rem;
                z-index: 10;
                top: -.5rem;
                left: -.5rem;

                &.tag--red {
                    background: $brand02;
                }
            }
            .teaser__img {
                height: 280px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                overflow: hidden;

                img {
                    object-fit: unset;
                    width: auto;
                    height: 260px;
                }
            }
            .teaser__inner {
                padding: 0 1.25rem 1.5rem;
                text-align: center;

                .teaser__title {
                    margin: 1rem 0 1rem;
                }
                .author {
                    font-size: $font-size-small;
                    color: $gray02;
                }
            }
            .teaser__footer {
                display: block;
                padding: 0 1.25rem 1.5rem;
                text-align: center;

                .teaser__price {
                    font-family: "Merriweather", sans-serif;
                    font-weight: 300;
                    color: $gray02;
                    font-size: 1.2rem;
                    margin-top: 2rem;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 40px;
                        height: 2px;
                        background: $gray04;
                        position: absolute;
                        top: -1rem;
                        left: 50%;
                        margin-left: -20px;
                    }
                }
                .price__info {
                    font-size: 12px;
                    display: block;
                    color: $gray02;
                }

            }

            .btn {
                margin-top: auto;
            }
            &:hover, &:focus {
                text-decoration: none;
                box-shadow: 0px 6px 16px 0px rgba(22,35,58,.2) !important;

                .teaser__footer {
                    .btn {
                        &:before {
                            height: 100%;
                        }
                    }
                }
            }
        }
        &.teaser--highlight {
            box-shadow: 0px 6px 16px 0px rgba(22, 35, 58, 0.5);
            border: solid 1px $gray03;
        }
    }
}

.productdetail {
    font-size: 16px;

    .product__title {
        text-align: center;
    }
    .product__subline {
        text-align: center;
        font-weight: $font-weight-bold;
        color: $gray03;
        font-size: 18px;
        margin-top: .75rem;
    }
    .product__tag {
        display: inline-block;
        background: $gray02;
        font-size: 15px;
        color: #fff;
        text-transform: uppercase;
        padding: .5rem 1rem;
        position: absolute;
        top: -10px;
        left: -10px;
    }
    .product__intro {
        background: $gray06;
        padding: 2.5rem;
        margin: 2rem 0;
        position: relative;

        .teaser__tag {
            background: $gray02;
            color: #fff;
            text-transform: uppercase;
            font-size: $font-size-small;
            position: absolute;
            padding: .5rem 1.5rem .3rem;
            z-index: 10;
            top: -1rem;
            left: -1rem;

            &.tag--red {
                background: $brand02;
            }
        }

        .product__image {
            text-align: center;

            img {
                width: 95%;
            }
        }

        
    }
    
    .product__subtitle {
        @include font-title-h2;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    .product__content {
        .product__text {
            padding-bottom: 2rem;

            ul {
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }
        .product__toggle {
            text-align: center;
            margin-top: 2rem;

            a {
                text-decoration: none;
                display: inline-block;
                position: relative;

                &:hover {
                    color: $gray01;
                }

                &:before, &:after {
                    transition-duration: 250ms;
                    transition-property: all;
                    transition-timing-function: ease;
                    position: absolute;
                    top: calc(50% + 1px);
                    width: 10px;
                    height: 2px;
                    background-color: $gray03;
                    transform-origin: bottom;
                    content: "";
                    border: none;
                }

                &:before {
                    right: -17px;
                    transform: translateY(-50%) translateX(1px) rotate(-45deg);
                }
                &:after {
                    right: -24px;
                    transform: translateY(-50%) translateX(-1px) rotate(45deg);
                }

                &.collapsed {
                    &:before {
                        right: -16px;
                        transform: translateY(-50%) translateX(1px) rotate(45deg);
                    }
                    &:after {
                        right: -25px;
                        transform: translateY(-50%) translateX(-1px) rotate(-45deg);
                    }
                }
            }
        }
        .product__more {
            margin-top: 2rem;
        }
    }
    .product__bonuses {
        position: relative;
        margin: 2rem 0;
        padding: 2rem;
        background: $gray06;

        .item {
            height: 100%;

            .bonus__item {
                background: #fff;
                height: 100%;
                padding: 2rem 1.5rem;
                text-align: center;

                .bonus__image {
                    height: 300px;
                    margin-bottom: 1.5rem;

                    img {
                        max-width: 240px;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .product__benefits {
        margin: 3rem 0;
    }
    .product__testis {
        margin: 3rem 0;
    }
    .product__author {
        padding: 2rem;
        margin: 3rem 0;
        background: $gray06;

        .author {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: solid 1px $gray04;

             &.first {
                 border-top: none;
                 margin-top: 0;
                 padding-top: 0;
             }

             .col-md-8, .col-md-4 {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
             }
        }
        .author__img {
            display: block;
            position: relative;
            width: 85%;
            margin-left: 5%;
            border: solid 3px $gray04;
            border-radius: 50%;
            margin-bottom: 0;
            overflow: hidden;

            &:before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
    }
    .product__accordion {
        margin: 3rem 0;
    }
    .product__form {
        background: $gray06;
        padding: 2rem 1rem 0;
    }
    .product__buy {
        border-top: solid 1px $gray04;
        margin-top: 1.5rem;
        padding-top: 1.5rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0 0 1rem;
            font-size: 14px;

            li {
                padding-left: 1.25rem;
                position: relative;
                color: $gray02;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 1rem;
                    width: 1rem;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    background: url("~img/gevestor/icon-check.svg");
                    background-repeat: no-repeat;
                    background-size: 90%;
                    background-position: left center;
                }
            }
        }

        .product__price {
            font-family: "Merriweather", sans-serif;
            font-weight: 300;
            color: $gray02;
            font-size: 1.5rem;
            position: relative;
            width: 100%;

            .price__suffix {
                font-size: 14px;
            }
        }
        .price__info {
            font-size: 14px;
            display: block;
            color: $gray02;
        }

        .col-md-5 {
            display: flex;
            flex-wrap: wrap;

            .btn {
                margin-left: auto;
                margin-top: auto;
            }
        }

        &.buy--sticky {
            background: $gray06;
            border-top: none;
            padding: 1rem;
            position: sticky;
            top: 150px;
            display: none;
            z-index: 100;

            .price__info {
                font-size: 11px;
            }

            .btn {
                margin-top: 1rem;
                font-size: 15px;
                width: 100%;
            }
        }
    }
    .owl-dots {
        margin-top: 2rem;
    }
}
.product__trust {
    border-top: solid 1px $gray04;
    border-bottom: solid 1px $gray04;
    padding: 1rem 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
    font-size: $font-size-small;

    &.trust--detail {
        margin-bottom: 0;
        
        .container {
            display: flex;
            justify-content: space-around;
        }
    }
    .trust__item {
        display: flex;
        align-items: center;

        img {
            height: 1.1rem;
            margin-left: .4rem;
        }
        .trust__rating--google, .trust__rating--trustpilot {
            margin: 0 .4rem;
        }
        .trust__rating--google, .trust__rating--google span {
            background-size: 20px 16px;
            height: 16px;
            width: 100px;
        }
        .trust__rating--google {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23d4dae0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>");
            background-repeat: repeat-x;
            display: inline-block;
            overflow: hidden;
            position: relative;

            span {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23ffbe1a' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>");
                background-repeat: repeat-x;
                display: block;
            }
        }
        .trust__rating--trustpilot, .trust__rating--trustpilot span {
            background-size: 25px 20px;
            height: 20px;
            width: 125px;
        }
        .trust__rating--trustpilot {
            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 96 96' style='enable-background:new 0 0 96 96%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%23cccccc%3B%7D%3C/style%3E%3Cpath class='st0' d='M0 0v96h96V0H0z M48 64.7L62.6 61l6.1 18.8L48 64.7z M60.9 55.4l-12.8 9.2l-20.8 15l7.9-24.2l-20.8-15h25.7 L48 16.2l7.9 24.2h25.7L60.9 55.4z'/%3E%3C/svg%3E");
            background-repeat: repeat-x;
            display: inline-block;
            overflow: hidden;
            position: relative;

            span {
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 96 96' style='enable-background:new 0 0 96 96%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%2300B67A%3B%7D%3C/style%3E%3Cpath class='st0' d='M0 0v96h96V0H0z M48 64.7L62.6 61l6.1 18.8L48 64.7z M60.9 55.4l-12.8 9.2l-20.8 15l7.9-24.2l-20.8-15h25.7 L48 16.2l7.9 24.2h25.7L60.9 55.4z'/%3E%3C/svg%3E");
                background-repeat: repeat-x;
                display: block;
            }
        }
    }
    &.trust--cat {
        margin-bottom: 0;
    }
}
.product__payment {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 3rem 0;

    .payment__item {
        max-width: 70px;

        &.img--large {
            max-width: 110px;
        }
    }
}
.product-nav {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    border-bottom: solid 1px $gray04;
    font-size: 15px;

    .nav-link {
        display: inline-block;
        color: $gray02;
        border-bottom: solid 2px transparent;

        &:hover, &:focus {
            color: $brand01;
            outline: none;
            border-bottom: solid 2px $brand01;
        }

        &.active {
            border-bottom: solid 2px $brand01;
        }
    }
}
@media (min-width: 768px) {
    .product-nav {
        .collapse {
            display: block !important;
        }
    }
}
// Responsive
// --------------------------------------------------
@import "responsive/products-sm";
