//
// Experts
// --------------------------------------------------
.experts {
    padding-bottom: 4rem;

    .teaser {
        display: block;
        height: 100%;
        position: relative;
        cursor: pointer;
        color: $gray01;
        text-decoration: none;
        padding-top: 100px;

        .teaser__img {
            height: 180px;
            width: 180px;
            border-radius: 50%;
            position: absolute;
            top: -90px;
            left: 50%;
            margin-left: -90px;
            border: solid 1px $gray04;
        }
        .teaser__info {
            display: block;
            padding: .5rem 0;
            margin: .5rem 0;
            border-top: solid 1px $gray04;
            border-bottom: solid 1px $gray04;

            .teaser__cat {
                margin-right: .5rem;
            }
        }
        .teaser__inner {
            position: relative;
            padding: 1.5rem 1.75rem;

            .teaser__title {
                margin-bottom: 0;
                margin-top: 0;
                transition: $transition-base;

                .link__arrow {
                    float: none;
                    margin-left: .5rem;
                    height: 12px;
                    width: 12px;
                }

                &:hover {
                    padding-left: .35rem;
                }
            }
        }
        .siegel {
            position: absolute;
            top: -90px;
            right: .25rem;

            .siegel__item {
                height: 150px;
            }
        }

        &:hover, &:focus {
            outline: none;
            box-shadow: $box-shadow !important;

            .teaser__inner {

                .teaser__title {
                    color: $brand01;
                    padding-left: .35rem;
                }
            }
        }
        &:focus {
            border-color: $brand01 !important;
        }
    }

    .card {
        margin-top: 110px;
        margin-bottom: 1rem;
        height: calc(100% - 110px - 1rem);

        &.active {
            display: block;
        }
    }

    .teaser-col {
        .teaser {
            border: solid 1px $gray04;
            height: calc(100% - 2rem - 100px);
            margin-bottom: 2rem;
            margin-top: 100px;
            box-shadow: none;
        }
    }

    .block__filter {
        text-align: center;
        margin-top: 3rem;

        .filter__item {
            .close-icon {
                transform: scale(.75);
                position: absolute;
                top: .5rem;
                right: .3rem;
                display: none;
            }
            &.filter__active {
                border-color: $gray04;
                padding-right: 2.3rem;
                
                .item__text {
                    color: $gray02;
                }
            
                .close-icon {
                    display: block;
                }

                &::before {
                    content: none;
                }
                &:hover {
                    background: none;
                }
            }
        }
    }
}
.expert {
    .block {
        padding: 0;
    }
    .article__info {
        display: flex;

        .article__cat {
            margin-right: 7px;
        }
        .expert__ankers {
            margin-left: auto;
            .btn {
                font-size: 15px;
                padding: 0.65rem 1.5rem;
                margin-left: 7px;
            }
        }
    }
    .expert__info {
        .row {
            .col-md-4, .col-md-8 {
                display: flex;
                align-items: center;
            }
        }

        .expert__image {
            margin-top: 0;
            width: 240px;
            height: 240px;
            border-radius: 50%;
            overflow: hidden;
            border: solid 1px $gray04;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
        .expert__short {
            p {
                margin-bottom: 0;
            }
        }
    }
    .expert__seals {
        background: $gray06;
        margin: 2rem 0;
        .owl-carousel {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .owl-carousel--experts {

            .owl-stage-outer {
                .item {
                    text-align: center;
                }
            }
        }
        .owl-dots {
            margin-bottom: 0;

            &.disabled {
                display: none;
            }
        }
    }
    .article__full {
        padding-bottom: 3rem;
        margin-top: 2rem;

        .article__start {
            .article__info {
                margin-bottom: 1rem;

                .info__text {
                    color: $gray02;
                    text-transform: uppercase;
                }
                .article__cat {
                    margin: 0 .5rem;
                }

            }
        }
    }

    .expert__newsletter {
        background: $gray06;
        padding: 2rem 3rem;
        margin: 0 0 3rem;

        .vnr-newsletter-inline {
            .section__title {
                text-align: center;
                margin-bottom: 1rem;
                h2 {
                    font-size: $font-size-h2;
                }
            }
            .vnr_newsletter-intro {
                margin-bottom: 2rem;
                text-align: center;
            }
            .form-group {
                margin-bottom: 1rem;
            }
            .vnr-newsletter-legal {
                font-size: 14px;
            }
            .btn-primary {
                background: $brand02;
                height: 51px;

                &:before {
                    background: $brand02;
                }
                &:hover {
                    background: darken($brand02, 5%);

                    &:before {
                        background: $brand02;
                        height: 0 !important;
                    }
                }
             
            }
        }
    }
    
    .expert__products {
        background: $gray05;
        padding: 3rem 0 2rem;

        .owl-carousel {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .expert__articles {
       padding: 4rem 0;

       .teaser {
            margin-bottom: 2rem;
            height: calc(100% - 2rem);
       }
    }
}
// Responsive
// --------------------------------------------------
@import "responsive/experts-sm";