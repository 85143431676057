//
// Icon
// --------------------------------------------------
.close-icon {
    cursor: pointer;
    display: inline-block;
    height: 26px;
    width: 26px;
    position: absolute;
    right: 0;
    top: 0;
    
    .horizontal {
        position: absolute;
        background-color:$gray02;
        width: 20px;
        height: 2px;
        left: 50%;
        margin-left: -10px;
        top: 50%;
        margin-top: -1px;
        transition: $transition-base;
        transform: rotate(-45deg);
        opacity: 1;
    }

    .vertical {
        position: absolute;
        background-color: $gray02;
        width: 2px;
        height: 20px;
        left: 50%;
        margin-left: -1px;
        top: 50%;
        margin-top: -10px;
        transition: $transition-base;
        transform: rotate(-45deg);
    }

    &:hover {
        .vertical {
            transition: $transition-base;
            transform: rotate(90deg);
         }
        .horizontal {
            transition: $transition-base;
            transform: rotate(90deg);
            opacity: 0;
        }
    }

    &.icon--gray {
        .horizontal {
            background-color: $gray03;
        }
    
        .vertical {
            background-color: $gray03;
        }
    }

    &.icon--orange {
        .horizontal {
            background-color: $brand01;
        }
    
        .vertical {
            background-color: $brand01;
        }
    }
}
.icon-links {
    margin-top: auto;
    margin-bottom: -2px;
    width: 100%;
}
.icon__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(26, 43, 63, 0.65);
    width: calc(33.3333% - 2px);
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding: .75rem 0;
    margin: 2px 1px 0;
    text-transform: uppercase;

    &.button--cta {
        background: rgba(111, 179, 22, 0.85);
    }
    .icon {
        width: 30px;
        height: auto;
        margin-bottom: 5px;
        filter: invert(100%) saturate(0) brightness(1000%);
    }

    &:hover {
        background: rgba(26, 43, 63, 0.85);
        text-decoration: none;
        color: #fff;
    }
}

.icon__more {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: $brand01;
}
  
// Responsive
// --------------------------------------------------
