@media screen and (max-width: 991px) {

  .productAnchor {
    scroll-margin-top: 2rem;
  }

  .productAnchor.scrolled {
    scroll-margin-top: 2rem;
  }

}



