//
// Stage SM
// --------------------------------------------------
@media (max-width: 767px) {
    .stage {
        background-size: cover;
        .col-md-5 {
            display: block;
            img {
                display: block; 
                width: 65%;
                margin-right: auto;
            }
        }

        .stage__title {
            font-size: 1.75rem;
            text-align: center;
        }

        .stage__bullets {
            font-size: 16px;
            margin: 1rem 0 2rem;
            text-align: center;

            li {
                padding-left: 0;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    position: relative;
                    background: url(~img/gevestor/icon-check.svg) left 2px no-repeat;
                    background-size: 100%;
                    margin-right: 8px;
                }
            }
        }
        .stage__content {
            margin: 1rem 0 2rem;
            font-size: 16px;

            ul {
                font-size: 16px;
        
                li {
                    &:before {
                        height: 17px;
                        width: 17px;
                        top: 4px;
                    }
                }
            }
        }
        .stage__form {
            display: block;
            margin-right: 0;

            .form-display {
                margin-bottom: .5rem;
            }
            .control-label {
                top: 10px !important;
            }
            input {
                &:focus ~ label,
                &:not(:placeholder-shown) ~ label {
                    top: -30px !important;
                }
            }

            .btn--cta--wrapper {
                position: relative;
                display: block;
            }
            .btn--cta {
                padding: 0.8rem 1rem;
                font-size: 15px;
                margin: 0 auto;
                width: 100%;
                right: unset;
            }
        }
        .nss_error_wrapper {
            top: 140px;
        }
        .stage__leagel {
            margin-top: .75rem;
          }
    }
}
