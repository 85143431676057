//
// Footer
// --------------------------------------------------
.footer {
    background: $gray02;
	color: #fff;
    position: relative;

	.footer__overlay {
		background: $gray01;
		height: 100%;
		width: 50vw;
		position: absolute;
		top: 0;
	}
	.footer--left, .footer--right {
		padding: 3rem 15px 0;
	}

	.footer--left {
		background: $gray01;
		z-index: 10;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	.footer--right {
		padding-left: 2rem !important;
	}
	.footer__logo {
		width: 200px;
		margin: 0 0 2rem;
	}
	.footer__title {
		@include font-title-h3;
		color: #fff;
		line-height: normal;
		margin-bottom: .75rem;
		border-left: solid 4px $brand01-ct;
		padding-left: .75rem;
	}
	.copyline {
		margin-top: auto;
		margin-bottom: 2rem;
		font-size: $font-size-small;
	}
}
.footer__nav {
	padding: 0;
	text-decoration: none;
	list-style: none;
	margin-bottom: 3rem;
	
	&.nav--horizontal {
		display: flex;
	}

	.footer__nav-item {
		padding-right: 2rem;

		.footer__nav-link {
			color: #fff;
			display: inline-block;
			position: relative;

			&:after {
                content: "";
                display: block;
                height: 1px;
                width: 0;
                background: $brand01;
                position: absolute;
                left: 0;
                bottom: 1px;
                transition: $transition-base;
            }

			&:hover, &:focus {
				color: $brand01;
				text-decoration: none;
				outline: none;
				
				&:after {
					width: 100%;
				}
			}

			&:hover {
				color: $brand01-ct;
				text-decoration: none;
			}
		}

        &:hover {
            color: $brand01;
            text-decoration: none;  
        }
	}
}
.sociallinks {
	display: flex;
	flex-wrap: wrap;

	.sociallink {
		flex: 0 0 50%;
		max-width: 50%;	
		text-transform: uppercase;
		font-size: 13px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		line-height: 1.5;
		margin-bottom: 1.5rem;
		font-weight: bold;
		color: #fff;
		padding-right: 30px;
		
		&:before {
			content: '';
			display: block;
			height: 40px;
			width: 40px;
			min-width: 40px;
			align-self: center;
			margin-right: 10px;
			background-size: 80% auto;
			background-repeat: no-repeat;
			background-position: center;
			background-color: #fff;
			border-radius: 8px;
			transition: $transition-base;
		}
		&:hover, &:focus {
			text-decoration: none;
			color: $brand01;
			outline: none;

			&::before {
				background-color: $brand01;
			}
		}

		&.link--facebook {
			&:before {
				background-image: url(~img/gevestor/social-facebook.svg);
			}
			&:hover, &:focus {
				&:before {
					background-image: url(~img/gevestor/social-facebook-white.svg);
				}
			}
		}
		&.link--instagram {
			&:before {
				background-image: url(~img/gevestor/social-instagram.svg);
			}
			&:hover, &:focus {
				&:before {
					background-image: url(~img/gevestor/social-instagram-white.svg);
				}
			}
		}
		&.link--twitter {
			&:before {
				background-image: url(~img/gevestor/social-twitter.svg);
			}
			&:hover, &:focus {
				&:before {
					background-image: url(~img/gevestor/social-twitter-white.svg);
				}
			}
		}
		&.link--pinterest {
			&:before {
				background-image: url(~img/gevestor/social-pinterest.svg);
			}
			&:hover, &:focus {
				&:before {
					background-image: url(~img/gevestor/social-pinterest-white.svg);
				}
			}
		}
		&.link--youtube {
			&:before {
				background-image: url(~img/gevestor/social-youtube.svg);
			}
			&:hover, &:focus {
				&:before {
					background-image: url(~img/gevestor/social-youtube-white.svg);
				}
			}
		}
	}
}
.footer__leagel {
	padding: 1.5rem 0;
	font-size: $font-size-xsmall;
	line-height: 1.5;
}
// Responsive
// --------------------------------------------------
@import "responsive/footer-md";
@import "responsive/footer-sm";