//
// Messages
// --------------------------------------------------
@media (max-width: 767px) {
    .message {
        padding: .75rem 2.5rem .75rem 1rem;
        font-size: $font-size-small;
        position: relative;

        .message__arrow {
            height: 12px;
            width: 12px;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            right: 1.25rem;
        }

        &:hover {
            padding: .75rem 1.5rem .75rem 1.5rem;

            .message__arrow {
                right: .75rem;
            }
        }
    }
    .messages--home {
        padding: 0;
        
        .message {
            width: calc(100% + 30px);
            margin-left: -15px;
        }
    }
}

// Responsive
// --------------------------------------------------
