//
// Footer MD
// --------------------------------------------------
@media (max-width: 991px) {
    .footer {
        .footer__overlay {
            display: none;
        }
        .footer--right {
            padding-left: 15px !important;
        }
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .sociallinks {
    
        .sociallink {
            
            &:before {
                height: 40px;
                width: 40px;
                background-size: auto 90%;
            }
        }
    }
}
// Responsive
// --------------------------------------------------
