//
// Display SM
// --------------------------------------------------
@media (max-width: 767px) {
    .experts {
        .teaser {
            .teaser__inner {
                padding: 1.5rem 1rem;
            }
        }
        .card {
            margin-top: 2rem;
            .teaser {
                max-width: 100%;
                width: 100%;
                padding-top: 60px;

                .siegel {
                    top: -60px;
                    .siegel__item {
                        height: 130px;
                    }
                }
                .teaser__inner {
                    padding: 1.5rem 1rem;
                }
            }

            &.card--img {
                margin-top: 110px;

                .teaser {
                    padding-top: 100px;
                }
            }
        }
    }
    .expert {
        .article__start {
            .article__info {
                flex-wrap: wrap;
                margin-top: 0;

                .expert__ankers {
                    margin: 0;
                    padding-top: .5rem;
                    width: 100%;

                    .btn {
                        margin-right: 7px;
                        margin-left: 0;
                        margin-right: 5px;
                        padding: .5rem .75rem;
                    }
                }
            }
        }
        .expert__info {
            .expert__image {
                width: 200px;
                height: 200px;
                margin: 0 auto 2rem;
            }
        }
        .expert__newsletter {
            padding: 2rem 1rem;
        }
    }
}