//
// Forms MD
// --------------------------------------------------
@media (max-width: 991px) {

	.form-display.form-group {
		
		.control-label {
			top: .5rem;
			left: 1rem;
			padding: 0 5px;
			font-size: 17px;
			margin-bottom: 0;
		}
		input {
			padding: 0 1rem 0;
			font-size: 17px;
			line-height: 42px;
			height: 42px;

			&:focus ~ label,
			&:not(:placeholder-shown) ~ label {
				font-size: 13px;
				top: -28px;
				left: -5px;
				padding: 1px 5px;
			}
		}
		.btn {
			height: 41px;
		}
	}

}