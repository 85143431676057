//
// Header
// --------------------------------------------------
.sticky-header {
    z-index: 1030;
    border-bottom: solid 1px $gray04;
}
.header {
    background: $gray06;

    .header__logo {
        display: inline-block;
        max-width: 210px;
        height: auto;
        padding: 1.5rem 0;  
        transition: $transition-base; 
        border-bottom: solid 2px transparent;
        border-top: solid 2px transparent;

        img {
            max-width: 210px;
            height: auto;
        }

        &:focus {
            outline: 0;
            border-bottom: solid 2px $brand02;

            img {
                opacity: .7;
            }
        }
    }

    .col-lg-8 {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
    }
    .header__links {
        display: flex;
        justify-content: flex-end;
        
        .header__link {
            @include font-nav;
            font-size: 14px;
            margin-left: 1.5rem;
            cursor: pointer;
            margin-bottom: -6px;
            border-bottom: solid 2px transparent;
            
            &.button--cta {
                display: none;
            }
            
            &:first-child {
                margin-left: 0;
            }
            
            .header__link__icon {
                height: 32px;
                width: 32px;
                margin: 0 auto .5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: $transition-base;

                img {
                    display: block;
                    width: 32px;
                    height: 32px;
                    transition: $transition-base;
                }

                &.icon--large {
                    height: 38px;
                    width: 38px;
                    margin: -.15rem auto .3rem;

                    img {
                        height: 38px;
                        width: 38px;
                    }
                }

            }
            
            &:hover, &:focus {
                color: $gray02;
                text-decoration: none;
                
                .header__link__icon {
                    transform: translateX(0) scale(1.15, 1.15);
                }
                
            }
        }
        .header__link:focus { 
            outline: none;
            border-bottom: solid 2px $brand02;
        }
    }

}

.header__search {
    position: absolute;
    width: 0;
    height: 100%;
    background: $gray06;
    top: 0;
    right: 15px;
    overflow: hidden;
    transition: $transition-base;
    
    .search__icon {
        position: absolute;
        top: .6rem;
        left: .5rem;
        width: 36px;
        height: auto;
    }
    .search__popular {
        margin-top: -1.8rem;
        font-size: $font-size-small;
        color: $gray03;
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;
        transition-delay: .75s;

        a {
            color: $gray03;
        }
    }
    .close-icon {
        right: 0;
        top: 1.8rem;
        display: block;
        height: 44px;
        width: 44px;
        position: absolute;
        cursor: pointer;

        span {
            background: $gray02;
        }
    }
    .form-group {
        margin-top: 1.25rem;

        .control-label {
            left: 3.75rem !important;
            opacity: 0;
            visibility: hidden;
        }
        .form-control {
            width: 100%;
            padding-left: 3.75rem;
        }
    }

    &.show {
        width: calc(100% - 30px);
        overflow: visible;

        .form-group {
            margin-top: 1.25rem;
    
            .control-label {
                display: none;
                opacity: 1;
                visibility: visible;
                transition: $transition-base;
            }
         
        }

        .search__popular {
            opacity: 1;
            visibility: visible;
            transition: $transition-base;
            transition-delay: .75s;
        }
    }

    .search__suggest {
        background: #fff;
        padding: 2rem 2rem 1.5rem;
        position: relative;
        z-index: 100;
        margin-top: -26px;
        margin-right: -1px;
        box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.15);
        z-index: 1400;
        display: none;

        .suggest__title {
            font-family: $font-family-display;
            margin-bottom: 1rem;
            border-bottom: solid 1px $gray04;
        }

        .suggest__list {
            list-style: none;
            padding: 0;

            &.suggest-articles-wrapper {
                padding-right: 2rem;
            }
            .suggest__item {
                margin-bottom: .75rem;
                font-size: $font-size-small;

                a {
                    color: $gray01;

                    &:hover {
                        text-decoration: none;
                        color: $gray02;
                    }
                }
            }
        }

        .suggest__link {
            padding-top: 1rem;
            border-top: solid 1px $gray04;
            transition: $transition-base;

            .link__arrow {
                float: right;
                height: 13px;
                width: 13px;
                margin-top: 9px;
            }

            &:hover {
                color: $gray02;
                padding-left: 5px;

                .link__arrow {
                    margin-right: -5px;
                }
            }
        }
        .suggest__product {
            display: block;
            background: $gray06;
            border: solid 1px transparent;
            line-height: 1.5;
            margin-bottom: 1rem;
            transition: all 150ms ease-in-out;
            font-family: $font-family-display;
            font-size: 17px;
            color: $gray02;

            .col-7 {
                padding-left: 0;
            }

            .product__img {
                height: 175px;
                padding: 1rem;

                img {
                    max-height: 100%;
                }

                &.img--full {
                    width: 100%;
                    height: 130px;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;

                    img {
                        display: block;
                        width: 75%;
                        height: auto;
                        max-height: 100%;
                    }
                }
            }

            .product__content {
                padding: 1rem 1rem 1rem 0;
            }
            &:hover {
                color: $gray01;
                box-shadow: 0px 6px 16px 0px rgba(22,35,58,0.25) !important;
                text-decoration: none;
            }
        }

        .suggest__expert {
            display: block;
            line-height: 1.5;
            margin-bottom: 1rem;
            transition: all 150ms ease-in-out;
            font-family: $font-family-display;
            font-size: 17px;
            color: $gray02;

            .col-7 {
                padding-left: 0;
            }
            
            .expert__img {
                img {
                    height: 110px;
                    width: 110px;
                    border-radius: 50%;
                    border: solid 1px $gray04;
                }
            }
            .expert__content {
                padding: 1rem 1rem 1rem 0;
                height: 100%;
                display: flex;
                align-items: center;
            }
            &:hover {
                color: $gray01;
            }

        }
    }
}
@media (min-width: 992px) {
    .sticked {
        .header {
            .header__logo {
                max-width: 160px;
                padding: .8rem 0;  
                transition: $transition-base; 
        
                img {
                    max-width: 160px;
                    height: auto;
                    transition: $transition-base;
                }
            }
            .header__links {
                display: flex;
                justify-content: flex-end;
                
                .header__link {
                    transition: $transition-base;

                    .header__link__icon {
                        height: 26px;
                        width: 26px;
                        margin: 0 auto 0;
                        transition: $transition-base;

                        img {
                            height: 26px;
                            width: 26px;
                            transition: $transition-base;
                        }
                    }

                    .hide-md {
                        display: none;
                    }
                }
            }
        }
        .header__search {
            .form-group {
                margin-top: .65rem;

                .form-control {
                    height: 42px;
                    font-size: 17px;
                    padding-left: 3rem;
                    margin-top: -1px;
                }
            }
            .search__icon {
                width: 30px;
                height: auto;
            }
            .close-icon {
                right: 0;
                top: .9rem;
                transform: scale(.8);
            }
            .search__popular {
                display: none;
            }
        }
    }
}

// Responsive
// --------------------------------------------------
@import "responsive/header-md";
@import "responsive/header-sm";