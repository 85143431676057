//
// Trust MD
// --------------------------------------------------
@media (max-width: 1199px) {
    .trust {
        &.trust--stage {
            height: auto;
            margin-top: 0;
            padding: 1.5rem 15px;
            background: #fff;
            border-top: solid 1px $gray04;
            border-bottom: solid 1px $gray04;
            width: calc(100% + 30px);
            margin-left: -15px;
        }
    }
}