//
// Trust SM
// --------------------------------------------------
@media (max-width: 1199px) {
    .trust {
        display: none;
        &.trust--stage {
            padding: .5rem 0;
        }
        .trust__elements {
            .col-sm-4 {
                border-right: none;
           
                .trust__element {
                    width: 100%;
                    justify-content: center;
                    padding: 1rem 0;
                    position: relative;
                    color: $gray02;

                    &:after {
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: $gray04;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -20px;
                    }
                }

                &:last-child {
                    .trust__element {
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }

        &.trust--sidebar {
            margin: 0 0 1rem;
        }
    }
}