//
// Banner SM
// --------------------------------------------------
@media (max-width: 767px) {
    .banner-ext {
        &.banner--mobile {
            display: block;
        }
        &.banner--desk {
            display: none;
        }
    }
    .banner {
        .banner__inner {
            padding: 3rem 0;
            text-align: center;
        }
        .col-md-4 {
            height: 250px;

            .banner__img {
                position: relative;
                bottom: revert;
                width: 70%; 
                margin-left: auto;
                margin-right: auto;
            }
        }
        .banner__title {
            font-size: 1.6rem;
        }
        .banner__content {

            ul {
                li {
                    padding-left: 0rem;
    
                    &:before {
                        position: relative;
                        margin-right: 8px;
                    }
                }
            }
        }
        .banner__bullets {
            font-size: 17px;

            li {
                &:before {
                    position: relative;
                    margin-right: 10px;
                }
            }
        }

        .banner__input {
            display: block;
            
            .btn-primary {
                margin-top: 1rem;
                width: 100%;
                font-size: 15px;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        &.banner--article-cat {
            .banner__bullets {
    
                li {
                    padding-left: 26px;

                    &:before {
                        position: absolute;
                    }
                }
            }
        }
        &.banner--full {
            .banner__inner {
                padding-left: 0;
            }

            .banner__img {
                bottom: -14rem;
    
                &.visible {
                    bottom: 0;
                }
            }
        }
        .banner__search {
            text-align: center;
            .banner__title {
                margin-bottom: 2rem;
            }
            form {
                margin: 0;
                .form-group {
                    margin: 0 auto;
                }
            }
        }
    }
    .number__item {
        text-align: center;

        .number_icon {
            width: 90px;
        }
        .counter {
            font-size: 38px;
        }
    }   
}
.embed-responsive {
    margin-top: 2rem;
}